import React, { Component } from "react";
import { Button, Form, Container } from "react-bootstrap";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";
import TextFieldGroup from "./text_field_group";

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData);
  }

  render() {
    const { errors } = this.state;
    return (
      <Container>
        <Form onSubmit={this.onSubmit}>
          <TextFieldGroup label="Email Address" type="email" name="email" value={this.state.email} onChange={this.onChange} error={errors.email} />
          <TextFieldGroup
            label="Password"
            type="password"
            name="password"
            value={this.state.password}
            onChange={this.onChange}
            error={errors.password}
          />
          <Button type="submit" style={{ width: "100%" }} className="btn btn-primary">
            Log In
          </Button>
        </Form>
        <center>
          <br />
          Need help with something?
          <hr />
          <h3>
            <a href="/#contact" className="a-color">
              Click here to contact one of our agents today!
            </a>
          </h3>
        </center>
      </Container>
    );
  }
}

LoginForm.propTypes = {
  loginUser: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(withRouter(LoginForm));
