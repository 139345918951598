import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  uploadCampaign,
  updateCampaign,
  addAdvertiser,
  updateAdvertiser,
  addCategory,
  updateCategory,
  addSubcategory,
  updateSubcategory,
  addState,
  updateState,
  addCity,
  updateCity
} from '../redux/actions/campaignActions';
import { Collapse, Button, Tabs, Tab } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import dateFormat from 'dateformat';
import queryString from 'query-string';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../assets/css/page.css';

import sanitizeHtml from 'sanitize-html-react';

const getColumnWidth = (rows, headerText, accessor) => {
  const maxWidth = 400;
  const magicSpacing = 10;
  const cellLength = Math.max(...rows.map(row => (`${row[accessor]}` || '').length), headerText.length);
  return Math.min(maxWidth, cellLength * magicSpacing);
};

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      filtered: [],
      campaignList: [],
      campaignStatusList: [],
      campaignData: [],
      categoryList: [],
      subcategoryList: [],
      stateList: [],
      cityList: [],
      advertiserList: [],
      uploadAdvertiserID: '',
      uploadStateID: '',
      uploadCityID: '',
      uploadCategoryID: '',
      uploadSubcategoryID: '',
      uploadStartDate: new Date(),
      uploadEndDate: new Date(),
      uploadZipCodes: '',
      uploadPhones: '',
      uploadDescription: '',
      uploadCopyCount: 0,
      uploadPrice: '',
      newAdvertiserName: '',
      newAdvertiserEmail: '',
      newAdvertiserPhone: '',
      newAdvertiserNotes: '',
      newCategory: '',
      newCategoryID: '',
      newSubcategory: '',
      newState: '',
      newStateID: '',
      newCity: '',
      editableCategoryArray: [],
      editableStateArray: [],
      editableCampaignStatusArray: [],
      errors: {}
    };

    this.onUploadCampaign = this.onUploadCampaign.bind(this);
    this.onUpdateCampaign = this.onUpdateCampaign.bind(this);
    this.onChangeAdvertiser = this.onChangeAdvertiser.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.onChangeSubcategory = this.onChangeSubcategory.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onAddAdvertiser = this.onAddAdvertiser.bind(this);
    this.onUpdateAdvertiser = this.onUpdateAdvertiser.bind(this);
    this.onAddCategory = this.onAddCategory.bind(this);
    this.onUpdateCategory = this.onUpdateCategory.bind(this);
    this.onAddSubcategory = this.onAddSubcategory.bind(this);
    this.onUpdateSubcategory = this.onUpdateSubcategory.bind(this);
    this.onAddState = this.onAddState.bind(this);
    this.onUpdateState = this.onUpdateState.bind(this);
    this.onAddCity = this.onAddCity.bind(this);
    this.onUpdateCity = this.onUpdateCity.bind(this);
    this.onChangeCopyCount = this.onChangeCopyCount.bind(this);

    this.renderCampaignEditable = this.renderCampaignEditable.bind(this);
    this.renderAdvertiserEditable = this.renderAdvertiserEditable.bind(this);
    this.renderCategoryEditable = this.renderCategoryEditable.bind(this);
    this.renderSubcategoryEditable = this.renderSubcategoryEditable.bind(this);
    this.renderStateEditable = this.renderStateEditable.bind(this);
    this.renderCityEditable = this.renderCityEditable.bind(this);

    this.keyPress = this.keyPress.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/login');
    }
    fetch('/api/campaign/list_campaigns')
      .then(res => res.json())
      .then(campaignList => {
        if (campaignList !== 'No Files') {
          this.setState({ campaignList }, () => {
            // console.log(campaignList);
          });
        }
      });
    fetch('/api/campaign/get_category')
      .then(res => res.json())
      .then(categoryList => {
        if (categoryList !== 'No Files') {
          this.setState({ categoryList }, () => {
            let tempSubcategoryList = [];
            this.state.categoryList.forEach((category, i) => {
              category.subcategories.forEach(subcategory => {
                subcategory.category_id = category.id;
                subcategory.category_name = category.category;
                tempSubcategoryList.push(subcategory);
              });
              this.setState({ subcategoryList: tempSubcategoryList });
            });
          });
        }
      });
    fetch('/api/campaign/get_state')
      .then(res => res.json())
      .then(stateList => {
        if (stateList !== 'No Files') {
          this.setState({ stateList }, () => {
            let tempCityList = [];
            this.state.stateList.forEach((state, i) => {
              state.cities.forEach(city => {
                city.state_id = state.id;
                city.state_name = state.state;
                tempCityList.push(city);
              });
              this.setState({ cityList: tempCityList });
            });
          });
        }
      });
    fetch('/api/campaign/list_advertisers')
      .then(res => res.json())
      .then(advertiserList => {
        if (advertiserList !== 'No Files') {
          this.setState({ advertiserList });
        }
      });
    this.setState({ campaignStatusList: [{ value: 'started' }, { value: 'ended' }, { value: 'voided' }, { value: 'uploaded' }] });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  onAddAdvertiser = e => {
    e.preventDefault();
    const advertiserData = new FormData();
    advertiserData.append('name', this.state.newAdvertiserName);
    advertiserData.append('email', this.state.newAdvertiserEmail);
    advertiserData.append('phone', this.state.newAdvertiserPhone);
    advertiserData.append('notes', this.state.newAdvertiserNotes);
    this.props.addAdvertiser(advertiserData, this.props.history);
  };
  onUpdateAdvertiser(updateAdvertiserID, updateAdvertiserName, updateAdvertiserEmail, updateAdvertiserPhone, updateAdvertiserNotes) {
    const formData = new FormData();
    formData.append('updateAdvertiserID', updateAdvertiserID);
    formData.append('updateAdvertiserName', updateAdvertiserName);
    formData.append('updateAdvertiserEmail', updateAdvertiserEmail);
    formData.append('updateAdvertiserPhone', updateAdvertiserPhone);
    formData.append('updateAdvertiserNotes', updateAdvertiserNotes);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.updateAdvertiser(formData, this.props.history);
  }
  onChangeAdvertiser = e => {
    let targetAdvertiserID = e.target.value;
    this.setState({ uploadAdvertiserID: targetAdvertiserID });
  };
  onAddCategory = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('category', this.state.newCategory);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.addCategory(formData, this.props.history);
  };
  onUpdateCategory(updateCategoryID, updateCategoryName) {
    const formData = new FormData();
    formData.append('updateCategoryID', updateCategoryID);
    formData.append('updateCategoryName', updateCategoryName);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.updateCategory(formData, this.props.history);
  }
  onChangeCategory = e => {
    this.setState({ uploadSubcategoryID: '' }); // reset subcategoryID
    let targetCategoryID = e.target.value;
    if (targetCategoryID === '') {
      this.setState({ uploadCategoryID: '' }); // reset selected categoryID
    } else {
      this.setState({ uploadCategoryID: targetCategoryID });
      this.state.categoryList.forEach(category => {
        if (parseInt(category.id) === parseInt(targetCategoryID)) {
          this.setState({ subcategoryList: category.subcategories });
        }
      });
    }
  };
  onChangeEditableCategory = (i, e) => {
    let categoryIDArray = [...this.state.editableCategoryArray]; // copy the array to a new array
    categoryIDArray[i] = e; // set value of the index to the new array
    this.setState({ editableCategoryArray: categoryIDArray }); // set state of the new array to the old array
  };
  onChangeEditableCampaignStatus = (i, e) => {
    let statusArray = [...this.state.editableCampaignStatusArray]; // copy the array to a new array
    statusArray[i] = e; // set value of the index to the new array
    this.setState({ editableCampaignStatusArray: statusArray }, () => {}); // set state of the new array to the old array
  };
  onAddSubcategory = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('subcategory', this.state.newSubcategory);
    formData.append('categoryID', this.state.newCategoryID);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.addSubcategory(formData, this.props.history);
  };
  onUpdateSubcategory(updateSubcategoryID, updateSubcategoryName, updateCategoryID) {
    const formData = new FormData();
    formData.append('updateSubcategoryID', updateSubcategoryID);
    formData.append('updateSubcategoryName', updateSubcategoryName);
    formData.append('updateCategoryID', updateCategoryID);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.updateSubcategory(formData, this.props.history);
  }
  onChangeSubcategory = e => {
    e.preventDefault();
    let targetSubcategoryID = e.target.value;
    this.setState({ uploadSubcategoryID: targetSubcategoryID });
  };
  onAddState = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('state', this.state.newState);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.addState(formData, this.props.history);
  };
  onUpdateState(updateStateID, updateStateName) {
    const formData = new FormData();
    formData.append('updateStateID', updateStateID);
    formData.append('updateStateName', updateStateName);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.updateState(formData, this.props.history);
  }
  onChangeState = e => {
    this.setState({ uploadCityID: '' }); // reset cityID
    let targetStateID = e.target.value;
    if (targetStateID === '') {
      this.setState({ uploadStateID: '' }); // reset selected stateID
    } else {
      this.setState({ uploadStateID: targetStateID });
      this.state.stateList.forEach(state => {
        if (parseInt(state.id) === parseInt(targetStateID)) {
          this.setState({ cityList: state.cities });
        }
      });
    }
  };
  onChangeEditableState = (i, e) => {
    let ids = [...this.state.editableStateArray]; // copy the array to a new array
    ids[i] = e; // set value of the index to the new array
    this.setState({ editableStateArray: ids }); // set state of the new array to the old array
  };
  onAddCity = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('city', this.state.newCity);
    formData.append('stateID', this.state.newStateID);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.addCity(formData, this.props.history);
  };
  onUpdateCity(updateCityID, updateCityName, updateStateID) {
    const formData = new FormData();
    formData.append('updateCityID', updateCityID);
    formData.append('updateCityName', updateCityName);
    formData.append('updateStateID', updateStateID);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.updateCity(formData, this.props.history);
  }
  onChangeCity = e => {
    let targetCityID = e.target.value;
    this.setState({ uploadCityID: targetCityID });
  };
  onChangeCopyCount = e => {
    let targetCopyCount = e.target.value;
    this.setState({ uploadCopyCount: targetCopyCount });
  };
  onStartDateChange = uploadStartDate => this.setState({ uploadStartDate });
  onEndDateChange = uploadEndDate => this.setState({ uploadEndDate });
  onUploadCampaign = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('userID', this.props.auth.user.id);
    formData.append('uploadAdvertiserID', this.state.uploadAdvertiserID);
    formData.append('uploadDescription', this.state.uploadDescription);
    formData.append('uploadStartDate', this.state.uploadStartDate);
    formData.append('uploadEndDate', this.state.uploadEndDate);
    formData.append('uploadCategoryID', this.state.uploadCategoryID);
    formData.append('uploadSubcategoryID', this.state.uploadSubcategoryID);
    formData.append('uploadStateID', this.state.uploadStateID);
    formData.append('uploadCityID', this.state.uploadCityID);
    formData.append('uploadZipCodes', this.state.uploadZipCodes);
    formData.append('uploadPhones', this.state.uploadPhones);
    formData.append('uploadCopyCount', this.state.uploadCopyCount);
    formData.append('uploadPrice', this.state.uploadPrice);
    formData.append('file', this.uploadInput.files[0]);
    this.props.uploadCampaign(formData, this.props.history);
  };
  onUpdateCampaign(updateCampaignID, updateCampaignStatus, updateCampaignDescription, updateCampaignRepeat) {
    if (isNaN(updateCampaignRepeat)) {
      alert('Campaign Repeat Must Be An Integer');
    } else {
      const formData = new FormData();
      formData.append('updateCampaignID', updateCampaignID);
      formData.append('updateCampaignStatus', updateCampaignStatus);
      formData.append('updateCampaignDescription', updateCampaignDescription);
      formData.append('updateCampaignRepeat', updateCampaignRepeat);
      formData.append('authUserRoleID', this.props.auth.user.role_id);
      this.props.updateCampaign(formData, this.props.history);
    }
  }
  /////////////////
  renderCampaignEditable(cellInfo) {
    // sanitize Html so we don't get html inserted into our database when user copies and paste from one field to another
    let editableList = this.state.campaignList;
    var sanitizeInnerHtml = sanitizeHtml(editableList[cellInfo.index][cellInfo.column.id]);
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onKeyPress={this.keyPress}
        onBlur={e => {
          var sanitizeOnBlur = sanitizeHtml(e.target.innerHTML);
          const data = [...editableList];
          data[cellInfo.index][cellInfo.column.id] = sanitizeOnBlur;
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeInnerHtml
        }}
      />
    );
  }
  renderAdvertiserEditable(cellInfo) {
    // sanitize Html so we don't get html inserted into our database when user copies and paste from one field to another
    let editableList = this.state.advertiserList;
    var sanitizeInnerHtml = sanitizeHtml(editableList[cellInfo.index][cellInfo.column.id]);
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onKeyPress={this.keyPress}
        onBlur={e => {
          var sanitizeOnBlur = sanitizeHtml(e.target.innerHTML);
          const data = [...editableList];
          data[cellInfo.index][cellInfo.column.id] = sanitizeOnBlur;
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeInnerHtml
        }}
      />
    );
  }
  renderCategoryEditable(cellInfo) {
    // sanitize Html so we don't get html inserted into our database when user copies and paste from one field to another
    let editableList = this.state.categoryList;
    var sanitizeInnerHtml = sanitizeHtml(editableList[cellInfo.index][cellInfo.column.id]);
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onKeyPress={this.keyPress}
        onBlur={e => {
          var sanitizeOnBlur = sanitizeHtml(e.target.innerHTML);
          const data = [...editableList];
          data[cellInfo.index][cellInfo.column.id] = sanitizeOnBlur;
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeInnerHtml
        }}
      />
    );
  }
  renderSubcategoryEditable(cellInfo) {
    // sanitize Html so we don't get html inserted into our database when user copies and paste from one field to another
    let editableList = this.state.subcategoryList;
    var sanitizeInnerHtml = sanitizeHtml(editableList[cellInfo.index][cellInfo.column.id]);
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onKeyPress={this.keyPress}
        onBlur={e => {
          var sanitizeOnBlur = sanitizeHtml(e.target.innerHTML);
          const data = [...editableList];
          data[cellInfo.index][cellInfo.column.id] = sanitizeOnBlur;
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeInnerHtml
        }}
      />
    );
  }
  renderStateEditable(cellInfo) {
    // sanitize Html so we don't get html inserted into our database when user copies and paste from one field to another
    let editableList = this.state.stateList;
    var sanitizeInnerHtml = sanitizeHtml(editableList[cellInfo.index][cellInfo.column.id]);
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onKeyPress={this.keyPress}
        onBlur={e => {
          var sanitizeOnBlur = sanitizeHtml(e.target.innerHTML);
          const data = [...editableList];
          data[cellInfo.index][cellInfo.column.id] = sanitizeOnBlur;
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeInnerHtml
        }}
      />
    );
  }
  renderCityEditable(cellInfo) {
    // sanitize Html so we don't get html inserted into our database when user copies and paste from one field to another
    let editableList = this.state.cityList;
    var sanitizeInnerHtml = sanitizeHtml(editableList[cellInfo.index][cellInfo.column.id]);
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onKeyPress={this.keyPress}
        onBlur={e => {
          var sanitizeOnBlur = sanitizeHtml(e.target.innerHTML);
          const data = [...editableList];
          data[cellInfo.index][cellInfo.column.id] = sanitizeOnBlur;
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeInnerHtml
        }}
      />
    );
  }
  ////////////
  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({ filtered: filtered });
  };
  keyPress = event => {
    // prevent contentEditable div from producing a new line
    if (event.charCode === 13) {
      event.preventDefault();
    }
  };
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  ////////////
  render() {
    const {
      errors,
      campaignList,
      campaignStatusList,
      categoryList,
      subcategoryList,
      stateList,
      cityList,
      advertiserList,
      uploadCategoryID,
      uploadStateID,
      editableCategoryArray,
      editableStateArray,
      editableCampaignStatusArray
    } = this.state;

    let urlParameters = queryString.parse(this.props.location.search);
    let defaultTab = urlParameters.tab !== 'undefined' ? urlParameters.tab : 'campaigns';

    return (
      <div className='body-body-container'>
        <div className='page-body'>
          <br />
          <h2>
            <a href='/dashboard' className='common-link' style={{ float: 'left' }}>
              {''}
              Back
            </a>
            <center>Campaigns</center>
          </h2>
          <hr />
          <Tabs defaultActiveKey={defaultTab}>
            <Tab eventKey='campaigns' title='Campaigns'>
              <Button style={{ margin: '20px auto' }} onClick={this.toggle}>
                Campaign Submission Form
              </Button>
              <Collapse in={this.state.collapse}>
                <div className='RequiredDivForCollapseToWork'>
                  <form onSubmit={this.onUploadCampaign}>
                    <div className='flexbox-container'>
                      <fieldset style={{ width: '100%' }}>
                        <legend visible='true'>Advertiser</legend>
                        <div className='flexbox-1' style={{ padding: '10px 0' }}>
                          <select onChange={this.onChangeAdvertiser} style={{ padding: '10px' }}>
                            <option value=''>Select Advertiser</option>
                            {advertiserList.map((advertiser, i) => (
                              <option key={i} value={advertiser.id}>
                                {advertiser.name} - {advertiser.uid}
                              </option>
                            ))}
                          </select>
                        </div>
                      </fieldset>
                    </div>
                    <div className='flexbox-container'>
                      <fieldset style={{ width: '100%' }}>
                        <legend visible='true'>Campaign Title/Description</legend>
                        <input
                          style={{ padding: '3px', width: '100%' }}
                          type='text'
                          placeholder='Title'
                          // maxLength='25'
                          value={this.state.uploadDescription}
                          onChange={e => {
                            const inputValue = e.target.value;
                            this.setState({ uploadDescription: inputValue });
                          }}
                        />
                      </fieldset>
                    </div>
                    <div className='flexbox-container'>
                      <fieldset>
                        <legend visible='true'>Schedule Run</legend>
                        <div style={{ margin: '10px' }}>
                          <b>Start Date:</b>
                          <DatePicker
                            // required="true"
                            name='uploadStartDate'
                            minDate={new Date()}
                            format='M/dd/yyyy'
                            onChange={this.onStartDateChange}
                            value={this.state.uploadStartDate}
                          />
                        </div>{' '}
                        <div style={{ margin: '10px' }}>
                          <b>End Date:</b>{' '}
                          <DatePicker
                            // required="true"
                            name='uploadEndDate'
                            minDate={new Date()}
                            format='M/dd/yyyy'
                            onChange={this.onEndDateChange}
                            value={this.state.uploadEndDate}
                            ref={ref => {
                              this.uploadEndDate = ref;
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset style={{ width: '100%' }}>
                        <legend>Type, Target, and Price</legend>
                        <div className='flexbox-1' style={{ padding: '10px 0', alignSelf: 'flex-end' }}>
                          <b>Repeat Ad?</b>{' '}
                          <select
                            value={this.state.uploadCopyCount}
                            onChange={this.onChangeCopyCount}
                            style={{ margin: '0px 30px 10px 0px', padding: '10px' }}
                          >
                            <option value='0'>No Repeat</option>
                            <option value='1'>Once Per Ad</option>
                            <option value='2'>Twice Per Ad</option>
                            <option value='4'>4 Times Per Ad</option>
                            <option value='8'>8 Times Per Ad</option>
                          </select>
                          <br />
                          <b>$</b>{' '}
                          <input
                            style={{ padding: '3px', width: '200px', marginBottom: '10px' }}
                            type='text'
                            placeholder='Price'
                            value={this.state.uploadPrice}
                            onChange={e => {
                              const inputValue = e.target.value;
                              const regex = /^[0-9.]*$/; // only allow numbers, dash
                              if (regex.test(inputValue)) {
                                this.setState({ uploadPrice: inputValue });
                              }
                            }}
                          />
                          <br />
                          <select onChange={this.onChangeCategory} style={{ padding: '10px' }}>
                            <option value=''>All Categories</option>
                            {categoryList.map((category, i) => (
                              <option key={i} value={category.id}>
                                {category.category}
                              </option>
                            ))}
                          </select>{' '}
                          {uploadCategoryID && subcategoryList ? (
                            <select onChange={this.onChangeSubcategory} style={{ padding: '10px' }} value={this.state.uploadSubcategoryID}>
                              <option value=''>All Subcategories</option>
                              {subcategoryList.map((subcategory, i) => {
                                return (
                                  <option key={i} value={subcategory.id}>
                                    {subcategory.subcategory}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='flexbox-1' style={{ padding: '10px 0', alignSelf: 'flex-end' }}>
                          <select onChange={this.onChangeState} style={{ padding: '10px' }}>
                            <option value=''>All States</option>
                            {this.state.stateList.map((state, i) => (
                              <option key={i} value={state.id}>
                                {state.state}
                              </option>
                            ))}
                          </select>{' '}
                          {uploadStateID ? (
                            <select onChange={this.onChangeCity} style={{ padding: '10px' }} value={this.state.uploadCityID}>
                              <option value=''>All Cities</option>
                              {cityList.map((city, i) => {
                                return (
                                  <option key={i} value={city.id}>
                                    {city.city}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='flexbox-1' style={{ padding: '10px 0', alignSelf: 'flex-end' }}>
                          <input
                            style={{ padding: '3px', width: '100%' }}
                            type='text'
                            placeholder='Zip Codes (dash separated)'
                            value={this.state.uploadZipCodes}
                            onChange={e => {
                              const inputValue = e.target.value;
                              const regex = /^[0-9,-]*$/; // only allow numbers, dash
                              if (regex.test(inputValue)) {
                                this.setState({ uploadZipCodes: inputValue });
                              }
                            }}
                          />
                        </div>
                        <div className='flexbox-1' style={{ padding: '10px 0', alignSelf: 'flex-end' }}>
                          <input
                            style={{ padding: '3px', width: '100%' }}
                            type='text'
                            placeholder='Phone Numbers (dash separated)'
                            value={this.state.uploadPhones}
                            onChange={e => {
                              const inputValue = e.target.value;
                              const regex = /^[0-9,-]*$/; // only allow numbers, dash
                              if (regex.test(inputValue)) {
                                this.setState({ uploadPhones: inputValue });
                              }
                            }}
                          />
                        </div>
                      </fieldset>
                    </div>
                    <div className='flexbox-container'>
                      <fieldset style={{ width: '100%' }}>
                        <legend visible='true'>Campaign File</legend>
                        <div className='flexbox-1' style={{ padding: '10px 0' }}>
                          <input
                            type='file'
                            ref={ref => {
                              this.uploadInput = ref;
                            }}
                            // required="true"
                          />
                        </div>
                      </fieldset>
                    </div>
                    <div>
                      <span style={{ color: '#f00', fontSize: '16px' }}>
                        {errors.error_description}
                        {errors.error_advertiser}
                        {errors.error_schedule}
                        {errors.error_scope}
                        {errors.error_file}
                        {errors.error_copyCount}
                        {errors.error_price}
                      </span>
                    </div>
                    <Button type='submit'>Submit Campaign</Button>
                  </form>
                  <br />
                </div>
              </Collapse>
              <center>
                <span style={{ color: '#f00' }}>{errors.errorUpdateCampaign}</span>
              </center>
              <ReactTable
                data={campaignList}
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
                columns={[
                  {
                    Header: '#',
                    minWidth: 40,
                    Cell: row => <span>{row.index + 1}</span>
                  },
                  {
                    Header: 'Action',
                    width: 90,
                    Cell: row => {
                      if (!editableCampaignStatusArray[row.index]) {
                        editableCampaignStatusArray[row.index] = row.original.status;
                      }
                      return (
                        <center>
                          <button
                            type='button'
                            onClick={() => {
                              if (window.confirm('Update this campaign?')) {
                                this.onUpdateCampaign(
                                  row.original.campaign_id,
                                  editableCampaignStatusArray[row.index],
                                  row.original.description,
                                  row.original.copy_count
                                );
                              }
                            }}
                          >
                            Update
                          </button>
                        </center>
                      );
                    }
                  },
                  {
                    Header: 'Media',
                    accessor: 'source',
                    Cell: row => (
                      <span>
                        <img
                          style={{ width: '100px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                          src={'/api/images/campaign/' + row.value}
                          alt='campaign_images'
                        />
                      </span>
                    )
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                    width: getColumnWidth(campaignList, 'Campaign Status', 'status'),
                    Cell: row => (
                      <span>
                        <span
                          style={{
                            color:
                              row.value === 'uploaded'
                                ? '#ffbf00'
                                : row.value === 'started'
                                ? '#238823'
                                : row.value === 'ended'
                                ? '#d2222d'
                                : '#000000',
                            transition: 'all .3s ease'
                          }}
                        >
                          &#x25cf;
                        </span>{' '}
                        <select
                          onChange={event => this.onChangeEditableCampaignStatus(row.index, event.target.value)}
                          value={editableCampaignStatusArray[row.index] ? editableCampaignStatusArray[row.index] : row.value}
                        >
                          {campaignStatusList.map((status, i) => {
                            return (
                              <option key={i} value={status.value}>
                                {status.value}
                              </option>
                            );
                          })}
                        </select>
                      </span>
                    )
                  },
                  {
                    Header: 'Title',
                    accessor: 'description',
                    Cell: this.renderCampaignEditable,
                    width: getColumnWidth(campaignList, 'Title', 'description')
                  },
                  {
                    Header: 'Repeat',
                    accessor: 'copy_count',
                    width: getColumnWidth(campaignList, 'Repeat', 'copy_count'),
                    Cell: this.renderCampaignEditable
                  },
                  {
                    Header: 'Start',
                    accessor: 'start_date',
                    width: getColumnWidth(campaignList, 'Start', 'start_date'),
                    Cell: row => <span>{dateFormat(row.value, 'GMT:m/dd/yyyy')}</span>
                  },
                  {
                    Header: 'End',
                    accessor: 'end_date',
                    width: getColumnWidth(campaignList, 'End', 'end_date'),
                    Cell: row => <span>{dateFormat(row.value, 'GMT:m/dd/yyyy')}</span>
                  },

                  {
                    Header: 'Phone',
                    accessor: 'phone',
                    width: getColumnWidth(campaignList, 'Phone', 'phone')
                  },
                  {
                    Header: 'Zip',
                    accessor: 'zip'
                  },
                  {
                    Header: 'City',
                    accessor: 'city',
                    width: getColumnWidth(campaignList, 'City Name', 'city')
                  },
                  {
                    Header: 'State',
                    accessor: 'state',
                    width: getColumnWidth(campaignList, 'State', 'state')
                  },
                  {
                    Header: 'Subcategory',
                    accessor: 'subcategory',
                    width: getColumnWidth(campaignList, 'Subcategory', 'subcategory')
                  },
                  {
                    Header: 'Category',
                    accessor: 'category',
                    width: getColumnWidth(campaignList, 'Category', 'category')
                  },
                  {
                    Header: 'Price',
                    accessor: 'price',
                    width: getColumnWidth(campaignList, 'Price', 'price')
                  },
                  {
                    Header: 'Last Updated',
                    accessor: 'timestamp',
                    width: getColumnWidth(campaignList, 'Last Updated', 'timestamp')
                  },
                  {
                    Header: 'Uploader',
                    accessor: 'first_name',
                    width: getColumnWidth(campaignList, 'Uploader', 'first_name')
                  }
                ]}
                defaultPageSize={20}
                showPaginationTop
                showPaginationBottom
                className='-striped -highlight'
              />
            </Tab>
            <Tab eventKey='advertisers' title='Advertisers'>
              <Button style={{ margin: '20px auto' }} onClick={this.toggle}>
                Add a New Advertiser
              </Button>
              <Collapse in={this.state.collapse}>
                <fieldset style={{ width: '100%' }}>
                  <legend visible='true'>New Advertiser</legend>
                  <form onSubmit={this.onAddAdvertiser}>
                    <input
                      style={{ padding: '10px', margin: '5px 3px', width: '100%' }}
                      type='text'
                      value={this.state.newAdvertiserName}
                      placeholder='Advertiser or Business Name'
                      onChange={e => {
                        const inputValue = e.target.value;
                        this.setState({ newAdvertiserName: inputValue });
                      }}
                    />{' '}
                    <input
                      style={{ padding: '10px', margin: '5px 3px', width: '100%' }}
                      type='text'
                      value={this.state.newAdvertiserEmail}
                      placeholder='Email'
                      onChange={e => {
                        const inputValue = e.target.value;
                        this.setState({ newAdvertiserEmail: inputValue });
                      }}
                    />
                    <input
                      style={{ padding: '10px', margin: '5px 3px', width: '100%' }}
                      type='text'
                      value={this.state.newAdvertiserPhone}
                      placeholder='Phone'
                      onChange={e => {
                        const inputValue = e.target.value;
                        const regex = /^[0-9,-]*$/; // only allow numbers, dash
                        if (regex.test(inputValue)) {
                          this.setState({ newAdvertiserPhone: inputValue });
                        }
                      }}
                    />
                    <textarea
                      placeholder='Additional Notes'
                      style={{ padding: '10px', margin: '5px 3px', width: '100%' }}
                      value={this.state.newAdvertiserNotes}
                      onChange={e => {
                        const inputValue = e.target.value;
                        this.setState({ newAdvertiserNotes: inputValue });
                      }}
                      rows='4'
                      cols='100%'
                    />
                    <span style={{ color: '#f00', fontSize: '16px' }}>{errors.errorAddAdvertiser}</span>
                    <br />
                    <Button type='submit'>Add Advertiser</Button>
                  </form>
                </fieldset>
              </Collapse>
              <br />
              <center>
                <span style={{ color: '#f00' }}>{errors.errorUpdateAdvertiser}</span>
              </center>
              <ReactTable
                data={advertiserList}
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
                columns={[
                  {
                    Header: '#',
                    minWidth: 40,
                    Cell: row => <span>{row.index + 1}</span>
                  },
                  {
                    Header: 'UID',
                    accessor: 'uid',
                    width: getColumnWidth(advertiserList, 'UID', 'uid')
                  },
                  {
                    Header: 'Name',
                    accessor: 'name',
                    width: getColumnWidth(advertiserList, 'Name', 'name'),
                    Cell: this.renderAdvertiserEditable
                  },
                  {
                    Header: 'Email',
                    accessor: 'email',
                    width: getColumnWidth(advertiserList, 'Email', 'email'),
                    Cell: this.renderAdvertiserEditable
                  },
                  {
                    Header: 'Phone',
                    accessor: 'phone',
                    width: getColumnWidth(advertiserList, 'Phone', 'phone'),
                    Cell: this.renderAdvertiserEditable
                  },
                  {
                    Header: 'Notes',
                    accessor: 'notes',
                    width: getColumnWidth(advertiserList, 'Notes', 'notes'),
                    Cell: this.renderAdvertiserEditable
                  },
                  {
                    Header: 'Action',
                    Cell: row => {
                      return (
                        <center>
                          <button
                            onClick={() =>
                              this.onUpdateAdvertiser(row.original.id, row.original.name, row.original.email, row.original.phone, row.original.notes)
                            }
                          >
                            Update
                          </button>
                        </center>
                      );
                    }
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom
                className='-striped -highlight'
              />
            </Tab>
            <Tab eventKey='categories' title='Categories'>
              <fieldset>
                <legend>New Category</legend>
                <form onSubmit={this.onAddCategory}>
                  <input
                    style={{ padding: '10px', margin: '10px 3px', width: '100%' }}
                    type='text'
                    value={this.state.newCategory}
                    placeholder='Category Name'
                    onChange={e => {
                      const inputValue = e.target.value;
                      this.setState({ newCategory: inputValue });
                    }}
                  />
                  <div style={{ color: '#f00', fontSize: '16px' }}>{errors.errorAddCategory}</div>
                  <Button type='submit'>Add Category</Button>
                </form>
              </fieldset>
              <center>
                <span style={{ color: '#f00' }}>{errors.errorUpdateCategory}</span>
              </center>
              <ReactTable
                data={categoryList}
                columns={[
                  {
                    columns: [
                      {
                        Header: '#',
                        minWidth: 30,
                        Cell: row => <center>{row.index + 1}</center>
                      },
                      {
                        Header: 'Category',
                        accessor: 'category',
                        Cell: this.renderCategoryEditable
                      },
                      {
                        Header: 'Action',
                        Cell: row => (
                          <center>
                            <button onClick={() => this.onUpdateCategory(row.original.id, row.original.category)}>Update</button>
                          </center>
                        )
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                className='-striped -highlight'
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
              />
            </Tab>
            <Tab eventKey='subcategories' title='Subcategories'>
              <fieldset>
                <legend>New Subcategory</legend>
                <form onSubmit={this.onAddSubcategory}>
                  <div className='flexbox-1' style={{ padding: '10px 0', alignSelf: 'flex-end' }}>
                    <select
                      onChange={e => {
                        const inputValue = e.target.value;
                        this.setState({ newCategoryID: inputValue });
                      }}
                      style={{ padding: '10px' }}
                    >
                      <option value=''>Select Category</option>
                      {categoryList.map((category, i) => (
                        <option key={i} value={category.id}>
                          {category.category}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='flexbox-1' style={{ padding: '10px 0', alignSelf: 'flex-end' }}>
                    <input
                      style={{ padding: '10px', width: '100%' }}
                      type='text'
                      value={this.state.newSubcategory}
                      placeholder='Subcategory Name'
                      onChange={e => {
                        const inputValue = e.target.value;
                        this.setState({ newSubcategory: inputValue });
                      }}
                    />
                  </div>
                  <div style={{ color: '#f00', fontSize: '16px' }}>{errors.errorAddSubcategory}</div>
                  <Button type='submit'>Add Subcategory</Button>
                </form>
              </fieldset>
              <center>
                <span style={{ color: '#f00' }}>{errors.errorUpdateSubcategory}</span>
              </center>
              <ReactTable
                data={subcategoryList}
                columns={[
                  {
                    columns: [
                      {
                        Header: '#',
                        minWidth: 30,
                        Cell: row => <center>{row.index + 1}</center>
                      },
                      {
                        Header: 'Subcategory',
                        accessor: 'subcategory',
                        Cell: this.renderSubcategoryEditable
                      },
                      {
                        Header: 'Category',
                        accessor: 'categoryName',
                        Cell: row => (
                          <center>
                            <select
                              onChange={event => this.onChangeEditableCategory(row.index, event.target.value)}
                              value={editableCategoryArray[row.index] ? editableCategoryArray[row.index] : row.original.category_id}
                            >
                              {categoryList.map((category, i) => {
                                return (
                                  <option key={i} value={category.id}>
                                    {category.category}
                                  </option>
                                );
                              })}
                            </select>
                          </center>
                        )
                      },
                      {
                        Header: 'Action',
                        Cell: row => {
                          if (!editableCategoryArray[row.index]) {
                            editableCategoryArray[row.index] = row.original.category_id;
                          }
                          return (
                            <center>
                              <button
                                onClick={() => this.onUpdateSubcategory(row.original.id, row.original.subcategory, editableCategoryArray[row.index])}
                              >
                                Update
                              </button>
                            </center>
                          );
                        }
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                className='-striped -highlight'
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
              />
            </Tab>
            <Tab eventKey='states' title='States'>
              <fieldset>
                <legend>New State</legend>
                <form onSubmit={this.onAddState}>
                  <input
                    style={{ padding: '10px', margin: '10px 3px', width: '100%' }}
                    type='text'
                    value={this.state.newState}
                    placeholder='State Name'
                    onChange={e => {
                      const inputValue = e.target.value;
                      this.setState({ newState: inputValue });
                    }}
                  />
                  <div style={{ color: '#f00', fontSize: '16px' }}>{errors.errorAddstate}</div>
                  <Button type='submit'>Add State</Button>
                </form>
              </fieldset>
              <center>
                <span style={{ color: '#f00' }}>{errors.errorUpdateState}</span>
              </center>
              <ReactTable
                data={stateList}
                columns={[
                  {
                    columns: [
                      {
                        Header: '#',
                        minWidth: 30,
                        Cell: row => <center>{row.index + 1}</center>
                      },
                      {
                        Header: 'State',
                        accessor: 'state',
                        Cell: this.renderStateEditable
                      },
                      {
                        Header: 'Action',
                        Cell: row => (
                          <center>
                            <button onClick={() => this.onUpdateState(row.original.id, row.original.state)}>Update</button>
                          </center>
                        )
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                className='-striped -highlight'
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
              />
            </Tab>
            <Tab eventKey='cities' title='Cities'>
              <fieldset>
                <legend>New City</legend>
                <form onSubmit={this.onAddCity}>
                  <div className='flexbox-1' style={{ padding: '10px 0' }}>
                    <select
                      onChange={e => {
                        const inputValue = e.target.value;
                        this.setState({ newStateID: inputValue });
                      }}
                      style={{ padding: '10px' }}
                    >
                      <option value=''>Select State</option>
                      {stateList.map((state, i) => (
                        <option key={i} value={state.id}>
                          {state.state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='flexbox-1' style={{ padding: '10px 0' }}>
                    <input
                      style={{ padding: '10px', width: '100%' }}
                      type='text'
                      value={this.state.newCity}
                      placeholder='City Name'
                      onChange={e => {
                        const inputValue = e.target.value;
                        this.setState({ newCity: inputValue });
                      }}
                    />
                  </div>
                  <div style={{ color: '#f00', fontSize: '16px' }}>{errors.errorAddcity}</div>
                  <Button type='submit'>Add City</Button>
                </form>
              </fieldset>
              <center>
                <span style={{ color: '#f00' }}>{errors.errorUpdateCity}</span>
              </center>
              <ReactTable
                data={cityList}
                columns={[
                  {
                    columns: [
                      {
                        Header: '#',
                        minWidth: 30,
                        Cell: row => <center>{row.index + 1}</center>
                      },
                      {
                        Header: 'City',
                        accessor: 'city',
                        Cell: this.renderCityEditable
                      },
                      {
                        Header: 'State',
                        accessor: 'state_name',
                        Cell: row => (
                          <center>
                            <select
                              onChange={event => this.onChangeEditableState(row.index, event.target.value)}
                              value={editableStateArray[row.index] ? editableStateArray[row.index] : row.original.state_id}
                            >
                              {stateList.map((state, i) => {
                                return (
                                  <option key={i} value={state.id}>
                                    {state.state}
                                  </option>
                                );
                              })}
                            </select>
                          </center>
                        )
                      },
                      {
                        Header: 'Action',
                        Cell: row => {
                          if (!editableStateArray[row.index]) {
                            editableStateArray[row.index] = row.original.state_id;
                          }
                          return (
                            <center>
                              <button onClick={() => this.onUpdateCity(row.original.id, row.original.city, editableStateArray[row.index])}>
                                Update
                              </button>
                            </center>
                          );
                        }
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                className='-striped -highlight'
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  uploadCampaign: propTypes.func.isRequired,
  updateCampaign: propTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps, {
  uploadCampaign,
  updateCampaign,
  addAdvertiser,
  updateAdvertiser,
  addCategory,
  updateCategory,
  addSubcategory,
  updateSubcategory,
  addState,
  updateState,
  addCity,
  updateCity
})(Dashboard);
