import axios from 'axios';
import { GET_ERRORS } from '../types/types';
import $ from 'jquery';

export const submitContact = (formData, history) => dispatch => {
  axios
    .post('/api/mail/submit_contact', formData)
    .then(res => {
      $('#Form-replaceable').replaceWith(
        "<div style='font-size:25px 20px; padding:20px; border:solid 1px #00AB66'><b>Thank you for contacting us.</b><br /><br />We will reach out to you shortly.</div>"
      );
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
