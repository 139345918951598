import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import PasswordAssistForm from "./components/form_passassist";
import "../assets/css/login.css";

class Login extends Component {
  render() {
    return (
      <div className="body-forgotpassword">
        <div className="box2-login">
          <center>
            <h1 className="h1_custom_index">Password assistance</h1>
            <h3 className="h3_custom_index">Enter the email address associated with your PDM account.</h3>
          </center>
          <Row className="">
            <Col className="col_icon_home">
              <PasswordAssistForm />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Login;
