import React from "react";
import classnames from "classnames";
import propTypes from "prop-types";
import { Form } from "react-bootstrap";

const TextFieldGroup = ({ name, placeholder, value, label, error, info, type, onChange, disabled }) => {
  return (
    <div>
      <Form.Group>
        {label ? (
          <Form.Label style={{ width: "100%" }}>
            {label === "Password" ? (
              <div>
                {label}
                <a href="/passassist" style={{ float: "right" }}>
                  Forgot your password?
                </a>
              </div>
            ) : (
              label
            )}
          </Form.Label>
        ) : (
          ""
        )}
        <Form.Control
          type={type}
          className={classnames("input2_custom_index", {
            "is-invalid ": error
          })}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

TextFieldGroup.propTypes = {
  name: propTypes.string.isRequired,
  placeholder: propTypes.string,
  value: propTypes.string.isRequired,
  info: propTypes.string,
  label: propTypes.string,
  error: propTypes.string,
  type: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  disabled: propTypes.string
};

TextFieldGroup.defaultProps = {
  type: "text"
};

export default TextFieldGroup;
