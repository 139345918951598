import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import LoginForm from "./components/form_login";
import "../assets/css/login.css";

class Login extends Component {
  render() {
    return (
      <div className="body-login">
        <div className="box1-login">
          <center>
            <h1 className="h1_custom_index">Agent Log In</h1>
            {/* <h3 className="h3_custom_index">Sign in to your PDM account</h3> */}
          </center>
          <Row className="">
            <Col className="col_icon_home">
              <LoginForm />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Login;
