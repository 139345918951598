import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from './../../redux/actions/authActions';
import { SocialIcon } from 'react-social-icons';

import logo from '../../assets/images/pdmlogo2.png';
import '../../assets/css/navbar.css';

class Header extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
    this.props.history.push('/login');
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const authLinks = () => (
      <Nav>
        {/* <Nav.Link href="/dashboard">ACCOUNT</Nav.Link> */}
        <Nav.Link href='/dashboard'>Dashboard</Nav.Link>
        <Nav.Link onClick={this.onLogoutClick.bind(this)}>
          ({user.first_name} {user.last_name}) Logout
        </Nav.Link>
      </Nav>
    );
    const dashboardLink = () => <>{/* <Nav.Link href="/dashboard">DASHBOARD</Nav.Link> */}</>;

    const guestLinks = <Nav>{/* <Nav.Link href='/login'>AGENT PORTAL</Nav.Link> */}</Nav>;

    const socialLinks = (
      <Nav>
        <Nav.Link href='#'>
          <div style={{ display: 'none' }}>
            <SocialIcon url='https://facebook.com/' bgColor='#ccc' style={{ height: 35, width: 35 }} />{' '}
            <SocialIcon url='https://twitter.com/' bgColor='#ccc' style={{ height: 35, width: 35 }} />{' '}
            <SocialIcon url='https://instagram.com/' bgColor='#ccc' style={{ height: 35, width: 35 }} />{' '}
            <SocialIcon url='https://linkedin.com/' bgColor='#ccc' style={{ height: 35, width: 35 }} />{' '}
          </div>
        </Nav.Link>
      </Nav>
    );

    const navLinks = (
      <Nav>
        <Nav.Link href='/about'>ABOUT US</Nav.Link>
        <Nav.Link href='/#contact'>CONTACT US | (833) 948-1073</Nav.Link>
      </Nav>
    );

    return (
      <div className='body-nav'>
        <Navbar collapseOnSelect expand='lg'>
          <Container>
            <Navbar.Brand>
              <a href='/'>
                <img src={logo} alt='pdm_logo' className='navbar-logo-img' />
              </a>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className='mr-auto'>{isAuthenticated ? dashboardLink() : navLinks}</Nav>
              {socialLinks} {isAuthenticated ? authLinks() : guestLinks}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

Header.propTypes = {
  logoutUser: propTypes.func.isRequired,
  auth: propTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Header);
