import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

// import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Partners extends Component {
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className='body-dashboard'>
        <Container className='container-dashboard'>
          <br />
          <h2>
            <a href='/dashboard' className='common-link' style={{ float: 'left' }}>
              {''}
              Back
            </a>
            <center>Partners</center>
          </h2>
          <hr />
        </Container>
      </div>
    );
  }
}

// export default Partners;

// const LoadingContainer = props => <div>Fancy loading container!</div>;
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps)(withRouter(Partners));
