import React, { Component } from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames'; // use if else for classnames
import { connect } from 'react-redux'; // connecting redux to component
import { verifyRegisterCode } from '../redux/actions/authActions';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';

class VerifyRegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      code: '',
      token: '',
      isValidVerifyPage: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.sendVerificationCode = this.sendVerificationCode.bind(this);
  }

  componentDidMount() {
    // get the page verification token from query url and pass to back end for check
    fetch('/api/users/verifytokenpage', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: this.props.match.params.id,
        tokenType: 'register'
      })
    })
      // to get response data from backend, do not use curly braces on first response
      .then(response => response.json({}))
      .then(response => {
        if (response.isValidPage === 'true') {
          this.setState({ email: response.email });
          this.setState({ token: response.token });
          this.setState({ isValidVerifyPage: true });
        } else {
          this.setState({ isValidVerifyPage: false });
        }
      });
  }

  componentWillMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const userVerificationData = {
      email: this.state.email,
      token: this.state.token,
      code: this.state.code
    };
    this.props.verifyRegisterCode(userVerificationData, this.props.history);
  }

  sendVerificationCode(e) {
    e.preventDefault();

    axios
      .post('/api/mail/sendcode', {
        email: this.state.email
      })
      .then(response => {
        $('div.sendCodeFeedback').replaceWith(
          "<div className='sendCodeFeedback'><b>Verification email sent</b><br /><br />Note: If you didn't receive our verification email:<ul><li>Confirm that your email address was entered correctly above.</li><li>Check your spam or junk email folder.</li><li>Sometimes email can takes time to arrive in your inbox. Give it 5 minutes.</li></ul></div>"
        );
      })
      .catch(error => {});
  }

  render() {
    const { errors } = this.state;
    if (this.state.isValidVerifyPage) {
      return (
        <Container>
          <div className='body-userverification'>
            <div className='modalbox1-custom-index'>
              <h1 className='h2_custom_index'>Verify email address</h1>
              <h3 className='h3_custom_index'>
                To verify your email, enter the code we've sent to {this.state.email} <a href='/register'>(Change)</a>
              </h3>
              <div>
                <br />
                Enter code
                <Form noValidate onSubmit={this.onSubmit}>
                  <Form.Group>
                    <Form.Control
                      className={classnames('input2_custom_index', {
                        'is-invalid ': errors.code
                      })}
                      name='code'
                      type='text'
                      maxLength={6}
                      value={this.state.code}
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.code}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Button type='submit' style={{ width: '100%', fontWeight: 'bold' }}>
                      Verify
                    </Button>
                  </Form.Group>
                </Form>
                <a href='_blank' onClick={this.sendVerificationCode}>
                  Resend code
                </a>
                <br />
                <br />
                <div className='sendCodeFeedback' />
              </div>
            </div>
          </div>
        </Container>
      );
    }

    if (!this.state.isValidVerifyPage) {
      return (
        <Container>
          <div className='body-userverification'>
            <div className='modalbox1-custom-index'>
              <h1 className='h2_custom_index'>There's a small problem</h1>
              <h3 className='h3_custom_index'>This invitation link isn't valid. Perhaps you already used it? If not, try registering again?</h3>
              <br />
              <br />
              <Button type='button' href='/register' style={{ fontWeight: 'bold' }}>
                Register a new account
              </Button>
            </div>
          </div>
        </Container>
      );
    }
  }
}

VerifyRegisterPage.propTypes = {
  verifyRegisterCode: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired
};

// use this to get reducer state into components, gets the state."auth" from combineRootReducer.js
// and put it into this auth. if "auth" from that file is changed to another word, then
// this state."auth" also needs to change. To get data, use this.props.auth.whatever
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { verifyRegisterCode }
)(withRouter(VerifyRegisterPage));
