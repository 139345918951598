import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import ContactForm from './components/form_contact';

import img_piggybank from '../assets/images/piggybank.png';
import img_signboard from '../assets/images/signboard.png';
import img_graphicdesign from '../assets/images/graphicdesign.png';

class JoinUs extends Component {
  render() {
    return (
      <div className='body-index'>
        <div className='body-group' style={{ backgroundColor: '#eeeeee' }}>
          <Container>
            <div className='group-content' style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
              <center>
                Thank you for considering joining our network of local businesses.
                <br />
                Your membership comes with many exciting benefits:
                <br />
                <br />
                <div className='flexbox-container' style={{ justifyContent: 'center' }}>
                  <div className='flexbox-1'>
                    <img src={img_piggybank} alt='thumbnail' className='img-generic-home' style={{ width: '250px' }} />
                    <br />
                    Generate Money with Little to No Cost or Effort
                  </div>
                  <div className='flexbox-1'>
                    <img src={img_signboard} alt='thumbnail' className='img-generic-home' style={{ width: '250px' }} />
                    <br /> Exclusive Pricing for Advertising Service Locally
                    <br />
                    All Over the US
                    <br />
                    <br />
                  </div>
                  <div className='flexbox-1'>
                    <img src={img_graphicdesign} alt='thumbnail' className='img-generic-home' style={{ width: '250px' }} />
                    <br />
                    Exclusive Promotional Digital Design Services
                  </div>
                </div>
                <div>
                  <br />
                  <span style={{ fontSize: '12px' }}>*Membership is only for business owners</span>
                </div>
              </center>
            </div>
          </Container>
        </div>

        <div className='body-group'>
          <center>
            <Container>
              <div id='contact'>
                <ContactForm />
              </div>
            </Container>
          </center>
        </div>
      </div>
    );
  }
}

export default JoinUs;
