import axios from 'axios';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../../utilities/setAuthToken';
import { GET_ERRORS, SET_CURRENT_USER } from '../types/types';

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post('/api/users/register', userData)
    .then(res => {
      history.push({
        pathname: '/registercode/' + res.data.token
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - Get User Token
export const loginUser = userData => dispatch => {
  axios
    .post('/api/users/login', userData)
    .then(res => {
      // save to local storage
      const { loginToken } = res.data;
      // set token to local storage
      localStorage.setItem('loginToken', loginToken);
      // set token to auth header
      setAuthToken(loginToken);
      // decode token to get user data
      const decoded = jwt_decode(loginToken);
      // set current user authentication
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Verify verifyRegisterCode
export const verifyRegisterCode = (code, history) => dispatch => {
  axios
    .post('/api/users/verifyregistercode', code)
    .then(res => {
      // save to local storage
      const { loginToken } = res.data;
      // set token to local storage
      localStorage.setItem('loginToken', loginToken);
      // set token to auth header
      setAuthToken(loginToken);
      // decode token to get user data
      const decoded = jwt_decode(loginToken);
      // set current user authentication
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Verify Email from PassAssist
export const verifyPassAssist = (email, history) => dispatch => {
  axios
    .post('/api/users/verifypassassist', email)
    .then(res => {
      // console.log(res.data.token);
      history.push({
        pathname: '/passcode/' + res.data.token
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Verify Code for PassCode
export const verifyPassCode = (code, history) => dispatch => {
  axios
    .post('/api/users/verifypasscode', code)
    .then(res => {
      history.push({
        pathname: '/passreset/' + res.data.token
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Reset password for forgetful user
export const resetPass = (code, history) => dispatch => {
  axios
    .post('/api/users/resetpass', code)
    .then(res => {
      // save to local storage
      const { loginToken } = res.data;
      // set token to local storage
      localStorage.setItem('loginToken', loginToken);
      // set token to auth header
      setAuthToken(loginToken);
      // decode token to get user data
      const decoded = jwt_decode(loginToken);
      // set current user authentication
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Set User Authentication
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('loginToken');
  // Remove auth header for future requests
  setAuthToken(false);
  //Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

// Add user role
export const createRole = (roleData, history) => dispatch => {
  axios
    .post('/api/users/create_role', roleData)
    .then(res => {
      history.push({
        search: '?tab=roles'
      });
      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const updateRole = (roleData, history) => dispatch => {
  axios
    .post('/api/users/update_role', roleData)
    .then(res => {
      history.push({
        search: '?tab=roles'
      });
      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const updateUser = (userData, history) => dispatch => {
  axios
    .post('/api/users/update_user', userData)
    .then(res => {
      history.push({
        search: '?tab=users'
      });
      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
