import React, { Component } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import classnames from "classnames"; // use if else for classnames
import { connect } from "react-redux"; // connecting redux to component
import { resetPass } from "../redux/actions/authActions";
import { Container, Button, Form } from "react-bootstrap";

class PassResetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userID: "",
      userName: "",
      password: "",
      password2: "",
      token: "",
      isValidVerifyPage: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // get the page verification token from query url and pass to back end for check
    fetch("/api/users/verifytokenpage", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: this.props.match.params.id,
        tokenType: "pass_reset"
      })
    })
      // to get response data from backend, do not use curly braces on first response
      .then(response => response.json({}))
      .then(response => {
        if (response.isValidPage === "true") {
          this.setState({ userID: response.userID });
          this.setState({ userName: response.userName });
          this.setState({ token: response.token });
          this.setState({ isValidVerifyPage: true });
        } else {
          this.setState({ isValidVerifyPage: false });
        }
      });
  }

  componentWillMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const passwordResetData = {
      userID: this.state.userID,
      userName: this.state.userName,
      password: this.state.password,
      password2: this.state.password2
    };
    this.props.resetPass(passwordResetData, this.props.history);
  }

  render() {
    const { errors } = this.state;
    if (this.state.isValidVerifyPage) {
      return (
        <Container>
          <div className="body-userverification">
            <div className="modalbox1-custom-index">
              <h1 className="h2_custom_index">Reset Your Password</h1>
              <h3 className="h3_custom_index">Enter your new password below:</h3>
              <div>
                <br />
                <Form noValidate onSubmit={this.onSubmit}>
                  <Form.Group>
                    <Form.Label>New Password:</Form.Label>
                    <Form.Control
                      className={classnames("input2_custom_index", {
                        "is-invalid ": errors.password
                      })}
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirm New Password:</Form.Label>
                    <Form.Control
                      className={classnames("input2_custom_index", {
                        "is-invalid ": errors.password2
                      })}
                      name="password2"
                      type="password"
                      value={this.state.password2}
                      onChange={this.onChange}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password2}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Button type="submit" style={{ width: "100%", fontWeight: "bold" }}>
                      Reset
                    </Button>
                  </Form.Group>
                </Form>
                {/* <a href="_blank" onClick={this.sendVerificationCode}>
                  Resend code
                </a> */}
                <br />
                <br />
                <div className="sendCodeFeedback" />
              </div>
            </div>
          </div>
        </Container>
      );
    }

    if (!this.state.isValidVerifyPage) {
      return (
        <Container>
          <div className="body-userverification">
            <div className="modalbox1-custom-index">
              <h1 className="h2_custom_index">There's a small problem</h1>
              <h3 className="h3_custom_index">The link you requested isn't valid. It seems you may have taken a wrong turn. </h3>
              <br />
              <br />
              <Button type="button" href="/" style={{ fontWeight: "bold" }}>
                Go Home
              </Button>
            </div>
          </div>
        </Container>
      );
    }
  }
}

PassResetPage.propTypes = {
  resetPass: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired
};

// use this to get reducer state into components, gets the state."auth" from combineRootReducer.js
// and put it into this auth. if "auth" from that file is changed to another word, then
// this state."auth" also needs to change. To get data, use this.props.auth.whatever
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { resetPass }
)(withRouter(PassResetPage));
