import React, { Component } from "react";
import { Container } from "react-bootstrap";
// import "../assets/css/index.css";

class Error404 extends Component {
  nextPath(path) {
    this.props.history.replace(path);
  }

  render() {
    return (
      <div className="body-login" style={{ height: "1000px", padding: "50px 50px" }}>
        <Container>
          <div className="font-face-clanbook" style={{ fontSize: "3.5em", fontWeight: "bold" }}>
            <span style={{ fontSize: "1.5em" }}>SORRY</span>
            <br />
            We couldn't find that page you're looking for.
          </div>
          <br />
          <span style={{ fontSize: "1.2em" }}>404. Page Not Found.</span>
          <br />
          <br />
          <button onClick={() => this.nextPath("/")} className="btn btn-primary">
            Go To Our Home Page &rarr;
          </button>
        </Container>
      </div>
    );
  }
}

export default Error404;
