import React, { Component } from 'react';
import '../../assets/css/footer.css';
import { Container } from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';

class FooterPage extends Component {
  render() {
    return (
      <div>
        <div>
          <div className='clearfooter' />
          <div className='clearfooter' />
        </div>
        <div className='footer'>
          <Container>
            <center>
              {' '}
              <div style={{ display: 'none' }}>
                <SocialIcon url='https://facebook.com/' bgColor='#ccc' style={{ height: 50, width: 50, margin: '0 15px' }} />{' '}
                <SocialIcon url='https://twitter.com/' bgColor='#ccc' style={{ height: 50, width: 50, margin: '0 15px' }} />{' '}
                <SocialIcon url='https://instagram.com/' bgColor='#ccc' style={{ height: 50, width: 50, margin: '0 30px 0 15px' }} />{' '}
                <SocialIcon url='https://linkedin.com/' bgColor='#ccc' sstyle={{ height: 50, width: 50, margin: '0 15px' }} />{' '}
              </div>
            </center>
            <br />
            <br />
            <div className='flexbox-container'>
              {/* <div className='flexbox-1'>
                <h3 style={{ color: '#fff' }}>About Us</h3>
                <div>
                  <a href='/about'>About</a>
                </div>
                <div>
                  <a href='/'>Home</a>
                </div>
              </div>
              <div className='flexbox-1'>
                <h3 style={{ color: '#fff' }}>Help</h3>
                <div>
                  <a href='/#contact'>Contact us</a>
                </div>
              </div> */}
              <div className='flexbox-1'>
                <h5 style={{ color: '#222' }}>Terms and Policy</h5>
                <div>
                  <a href='/about/termsofuse'>Terms of Use</a> &bull; <a href='/about/privacypolicy'>Privacy Policy</a>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default FooterPage;
