import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import dashboard_campaigns from '../assets/images/dashboard_campaigns.png';
import dashboard_displays from '../assets/images/dashboard_displays.png';
import dashboard_sales from '../assets/images/dashboard_sales.png';
import dashboard_users from '../assets/images/dashboard_users.png';
import '../assets/css/dashboard.css';

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className='body-dashboard'>
        <Container className='container-dashboard'>
          <center>
            <br />
            <h2>DASHBOARD</h2>
          </center>
          <hr />
          <div className='flexbox-container' style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
            <a href='/company/campaigns' className='flexbox-1 common-link'>
              <div className='flexbox-1' style={{ padding: '10px', margin: '10px', fontSize: '40px' }}>
                <center>
                  <img src={dashboard_campaigns} alt='thumbnail' className='dashboard-icon' />
                  <br />
                  Campaigns
                </center>
              </div>
            </a>
            <a href='/company/displays' className='flexbox-1 common-link'>
              <div className='flexbox-1' style={{ padding: '10px', margin: '10px', fontSize: '40px' }}>
                <center>
                  <img src={dashboard_displays} alt='thumbnail' className='dashboard-icon' />
                  <br />
                  Displays
                </center>
              </div>
            </a>

            <a href='/company/partners' className='flexbox-1 common-link'>
              <div className='flexbox-1' style={{ padding: '10px', margin: '10px', fontSize: '40px' }}>
                <center>
                  <img src={dashboard_sales} alt='thumbnail' className='dashboard-icon' />
                  <br />
                  Sales
                </center>
              </div>
            </a>

            <a href='/company/users' className='flexbox-1 common-link'>
              <div className='flexbox-1' style={{ padding: '10px', margin: '10px', fontSize: '40px' }}>
                <center>
                  <img src={dashboard_users} alt='thumbnail' className='dashboard-icon' />
                  <br />
                  Team
                </center>
              </div>
            </a>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps)(withRouter(Dashboard));
