import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import icon_platform from "../assets/images/platform.png";
import icon_code from "../assets/images/code.png";
import icon_mediation from "../assets/images/mediation.png";
import icon_heart from "../assets/images/heart.png";
import img_women from "../assets/images/women.jpg";

class About extends Component {
  render() {
    return (
      <div className="body-index">
        <div class="body-group">
          <Container>
            <div class="group-content" style={{ fontSize: "1.5em", fontWeight: "bold" }}>
              <center>
                Traditional media such as newspaper, magazines, radio, and billboards was the norm. But there is a new and better frontier: the
                digital advertisement space.
              </center>
            </div>
          </Container>
        </div>

        <div class="body-group" style={{ backgroundColor: "#eeeeee" }}>
          <Container>
            <div class="group-content" style={{ fontSize: "1.5em" }}>
              <center>
                Pink Dot Media is a media and marketing company that helps brands expand their reach on the largest women's network in the most
                receptive environments through the digital medium.
              </center>
            </div>
          </Container>
        </div>
        <div class="body-group">
          <Container>
            <div class="group-content">
              <center>
                <h2>What We Do and Are Doing</h2>
              </center>
              <br />
              <div class="flexbox-container">
                <div class="flexbox-1" style={{ padding: "25px" }}>
                  <center>
                    <img src={icon_platform} alt="thumbnail" style={{ width: "150px" }} />
                    <h3>A Better Platform</h3>
                  </center>
                  <br />
                  We are constantly building a better digital platform that gives advertisers better analytics to help them make the best choices.
                </div>
                <div class="flexbox-1" style={{ padding: "25px" }}>
                  {" "}
                  <center>
                    <img src={icon_code} alt="thumbnail" style={{ width: "150px" }} />
                    <br />
                    <h3>Faster and Efficient Software</h3>
                  </center>
                  <br />
                  Improving our software is important, in terms of speedy content deliver, security, and reliability.
                </div>
              </div>
              <div class="flexbox-container">
                <div class="flexbox-1" style={{ padding: "25px" }}>
                  <center>
                    <img src={icon_mediation} alt="thumbnail" style={{ width: "150px" }} />
                    <h3>Content Mediation</h3>
                  </center>
                  <br />
                  Besides helping our customers reach their target audience, we believe in doing the right thing and serving the right message.
                  Especially, the ones that help people achieve goals, live better lives, and do more good.
                </div>
                <div class="flexbox-1" style={{ padding: "25px" }}>
                  <center>
                    <img src={icon_heart} alt="thumbnail" style={{ width: "150px" }} />
                    <h3>Community and Giving Back</h3>
                  </center>
                  <br />
                  Because our platform is digital, we give back in so many ways. No paper means no cutting trees. We're energy efficient because of
                  our tiny digital footprints. We also partner with local communities to raise awareness of issues and events that goes on everyday.
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div class="body-group">
          <Container>
            <center>
              <img src={img_women} alt="thumbnail" style={{ width: "100vh" }} />
            </center>
          </Container>
        </div>
        <div class="body-group" style={{ backgroundColor: "#eeeeee", fontWeight: "bold" }}>
          <Container>
            <div class="group-content">
              Our job is to build a platform that get people talking and interacting with your brand longer than traditional media. Pink Dot Media and
              its digital network inform and engage women while providing customized marketing opportunities.
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default About;
