import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import '../assets/css/page.css';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

// import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import queryString from 'query-string';

class Displays extends Component {
  constructor() {
    super();
    this.state = {
      domain: '',
      filtered: [],
      displayList: [],
      fieldDelimiter: '',
      subfieldDelimiter: ''
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/login');
    }

    fetch('/api/display/list_status')
      .then(res => res.json())
      .then(displayList => {
        this.setState({ displayList });
      });

    fetch('/api/environment/get_env')
      .then(res => res.json())
      .then(envList => {
        this.setState({ fieldDelimiter: envList[0].campaignFieldDelimiter });
        this.setState({ subfieldDelimiter: envList[0].campaignSubfieldDelimiter });
      });

    if (window.location.hostname === 'localhost') {
      this.setState({ domain: 'http://localhost:5000' });
    } else {
      this.setState({ domain: window.location.hostname });
    }
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({ filtered: filtered });
  };

  render() {
    var { displayList, fieldDelimiter } = this.state;
    let urlParameters = queryString.parse(this.props.location.search);
    let defaultTab = urlParameters.tab !== 'undefined' ? urlParameters.tab : 'users';
    return (
      <div className='page-body-container'>
        <div className='page-body'>
          <br />
          <h2>
            <a href='/dashboard' className='common-link' style={{ float: 'left' }}>
              {''}
              Back
            </a>
            <center>TV Displays</center>
          </h2>
          <hr />
          <Tabs defaultActiveKey={defaultTab} id='uncontrolled-tab-example'>
            <Tab eventKey='displays' title='Displays'>
              <ReactTable
                data={displayList}
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
                columns={[
                  {
                    columns: [
                      {
                        expander: true,
                        Header: () => <strong>TV</strong>,
                        minWidth: 30,
                        Expander: ({ isExpanded, ...rest }) => <div>{isExpanded ? <center>&#x2299;</center> : <center>&#x2295;</center>}</div>,
                        style: {
                          cursor: 'pointer',
                          fontSize: 25,
                          padding: '0',
                          textAlign: 'center',
                          userSelect: 'none'
                        }
                      },
                      {
                        Header: 'Last Callback Time',
                        accessor: 'date',
                        minWidth: 220,
                        Cell: row => <span>{dateFormat(row.value, 'ddd, mmm d @ h:MMTT')}</span>
                      },
                      {
                        Header: 'Status',
                        accessor: 'status',
                        Cell: row => (
                          <span>
                            <span style={{ color: row.value === 'On' ? '#57d500' : '#ff2e00' }}>&#x25cf;</span> {row.value}
                          </span>
                        ),
                        minWidth: 60
                      },
                      {
                        Header: 'Source',
                        accessor: 'source',
                        minWidth: 60,
                        Cell: row => (
                          <span>
                            <span style={{ color: row.value === 'PDM' ? '#57d500' : '#ff2e00' }}>&#x25cf;</span> {row.value}
                          </span>
                        )
                      },
                      {
                        Header: 'Business Name',
                        accessor: 'name',
                        minWidth: 150
                      },
                      {
                        Header: 'Address',
                        accessor: 'address',
                        minWidth: 250
                      },
                      {
                        Header: 'City',
                        accessor: 'city'
                      },
                      {
                        Header: 'State',
                        accessor: 'state'
                      },
                      {
                        Header: 'Zip',
                        accessor: 'zip',
                        minWidth: 70
                      },
                      {
                        Header: 'Phone',
                        accessor: 'phone'
                      },
                      {
                        Header: 'Subcategory',
                        accessor: 'subcategory'
                      },
                      {
                        Header: 'Category',
                        accessor: 'category'
                      }
                    ]
                  }
                ]}
                SubComponent={row => {
                  return (
                    <div>
                      {row.original.campaigns.map((image, index) => {
                        let imageSource = image;

                        if (imageSource.includes('copy')) {
                          let imageSourceSplit = imageSource.split(fieldDelimiter);
                          let originalCopySortID = imageSourceSplit[2]
                            .split('.')
                            .slice(0, -1)
                            .join('.');
                          let imageSourceCopy = originalCopySortID + fieldDelimiter + imageSourceSplit[1] + fieldDelimiter + imageSourceSplit[2];
                          imageSource = imageSourceCopy.replace('_copy', '');
                        } else if (!imageSource.includes('___0___') && !imageSource.includes('copy')) {
                          let imageSourceSplit = imageSource.split(fieldDelimiter);
                          let originalCopySortID = imageSourceSplit[2]
                            .split('.')
                            .slice(0, -1)
                            .join('.');
                          imageSource = originalCopySortID + fieldDelimiter + imageSourceSplit[1] + fieldDelimiter + imageSourceSplit[2];
                        }

                        // let imageOriginal = imageSource.replace('copy', '')
                        return (
                          <div
                            key={index}
                            style={{
                              backgroundColor: 'black',
                              width: '150px',
                              height: '150' * (9 / 16) + 'px',
                              border: 'solid 2px black',
                              margin: '10px',
                              float: 'left'
                            }}
                          >
                            <img
                              key={index}
                              style={{ height: '100%', width: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                              src={'/api/images/campaign/' + imageSource}
                              alt='campaign_images'
                            />
                          </div>
                        );
                      })}
                      <div style={{ color: '#000', clear: 'both' }}>{row.original.additional_data}</div>
                    </div>
                  );
                }}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom
                className='-striped -highlight'
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(mapStateToProps)(withRouter(Displays));
