import React, { Component } from "react";
import RegisterForm from "./components/form_register";
import "../assets/css/login.css";

export default class Register extends Component {
  render() {
    return (
      <div className="body-login">
        <div className="box1-login" style={{ marginBottom: "100px" }}>
          <center>
            <h1 className="h1_custom_index">Register</h1>
            <h3 className="h3_custom_index">Sign up for a PDM account</h3>
          </center>
          <RegisterForm />
        </div>
      </div>
    );
  }
}
