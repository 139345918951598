import axios from 'axios';
import { GET_ERRORS } from '../types/types';

export const uploadCampaign = (fileData, history) => dispatch => {
  axios
    .post('/api/campaign/upload_campaign', fileData)
    .then(res => {
      alert('You have successfully added to the campaign');
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}; // upload campaign ad
export const updateCampaign = (fileName, history) => dispatch => {
  axios
    .post('/api/campaign/update_campaign', fileName)
    .then(res => {
      // alert('You have successfully updated this campaign');
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}; // end campaign ad
export const startCampaign = (fileName, history) => dispatch => {
  axios
    .post('/api/campaign/start', fileName)
    .then(res => {
      alert('You have successfully started this campaign');
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}; // start campaign ad
export const endCampaign = (fileName, history) => dispatch => {
  axios
    .post('/api/campaign/end', fileName)
    .then(res => {
      alert('You have successfully ended this campaign');
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}; // end campaign ad
export const addAdvertiser = (formData, history) => dispatch => {
  axios
    .post('/api/campaign/add_advertiser', formData)
    .then(res => {
      history.push({
        search: '?tab=advertisers'
      });
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}; // create new advertiser
export const updateAdvertiser = (data, history) => dispatch => {
  axios
    .post('/api/campaign/update_advertiser', data)
    .then(res => {
      history.push({
        search: '?tab=advertisers'
      });
      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
export const addCategory = (formData, history) => dispatch => {
  axios
    .post('/api/campaign/add_category', formData)
    .then(res => {
      history.push({
        search: '?tab=categories'
      });
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
export const updateCategory = (data, history) => dispatch => {
  axios
    .post('/api/campaign/update_category', data)
    .then(res => {
      history.push({
        search: '?tab=categories'
      });
      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
export const addSubcategory = (formData, history) => dispatch => {
  axios
    .post('/api/campaign/add_subcategory', formData)
    .then(res => {
      history.push({
        search: '?tab=subcategories'
      });
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
export const updateSubcategory = (data, history) => dispatch => {
  axios
    .post('/api/campaign/update_subcategory', data)
    .then(res => {
      history.push({
        search: '?tab=subcategories'
      });
      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
export const addState = (formData, history) => dispatch => {
  axios
    .post('/api/campaign/add_state', formData)
    .then(res => {
      history.push({
        search: '?tab=states'
      });
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
export const updateState = (data, history) => dispatch => {
  axios
    .post('/api/campaign/update_state', data)
    .then(res => {
      history.push({
        search: '?tab=states'
      });
      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
export const addCity = (formData, history) => dispatch => {
  axios
    .post('/api/campaign/add_city', formData)
    .then(res => {
      history.push({
        search: '?tab=cities'
      });
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
export const updateCity = (data, history) => dispatch => {
  axios
    .post('/api/campaign/update_city', data)
    .then(res => {
      history.push({
        search: '?tab=cities'
      });
      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
