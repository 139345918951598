import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

class Development extends Component {
  constructor() {
    super();
    this.state = {
      // data: makeData(),
      data: [],
      filtered: [],
      displayData: [],
      sortIDDelimiter: '',
      sortFieldDelimiter: '',
      campaignFieldDelimiter: '',
      campaignSubfieldDelimiter: ''
    };
  }

  componentDidMount() {
    fetch('/api/display/get_displays')
      .then(res => res.json())
      .then(displayData => {
        this.setState({ data: displayData });
      });

    fetch('/api/environment/get_env')
      .then(res => res.json())
      .then(envList => {
        this.setState({ campaignFieldDelimiter: envList[0].campaignFieldDelimiter });
        this.setState({ campaignSubfieldDelimiter: envList[0].campaignSubfieldDelimiter });
      });

    if (window.location.hostname === 'localhost') {
      this.setState({ domain: 'http://localhost:5000' });
    } else {
      this.setState({ domain: window.location.hostname });
    }
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({ filtered: filtered });
  };

  render() {
    var { data } = this.state;
    // data = data.map(row => {
    //   return row;
    // });
    return (
      <div className='body-index'>
        <ReactTable
          SubComponent={row => {
            return (
              <div style={{ width: '350px' }}>
                <Carousel>
                  {row.original.campaigns.map((image, index) => {
                    let imageSource = image;
                    var sortFieldSplit = imageSource.split(this.state.sortFieldDelimiter);
                    let sortTypeSplit = sortFieldSplit[0].split(this.state.sortIDDelimiter);
                    let sortSubtypeSplit = sortTypeSplit[1].split(this.state.campaignSubfieldDelimiter);

                    if (sortSubtypeSplit[2] === this.state.campaignKeywordDupSort || sortSubtypeSplit[2] === this.state.campaignKeywordDupClone) {
                      let campaignFieldSplit = sortFieldSplit[1].split(this.state.campaignFieldDelimiter);
                      let campaignID = campaignFieldSplit[0];
                      let originalDubSortID = campaignID.replace(this.state.campaignSubfieldDelimiter, '');

                      if (sortSubtypeSplit[2] === this.state.campaignKeywordDupSort) {
                        imageSource =
                          originalDubSortID + this.state.sortIDDelimiter + sortTypeSplit[1] + this.state.sortFieldDelimiter + sortFieldSplit[1];
                      }

                      if (sortSubtypeSplit[2] === this.state.campaignKeywordDupClone) {
                        let originalDupSortType =
                          sortSubtypeSplit[0] +
                          this.state.campaignSubfieldDelimiter +
                          sortSubtypeSplit[1] +
                          this.state.campaignSubfieldDelimiter +
                          this.state.campaignKeywordDupSort;
                        imageSource =
                          originalDubSortID + this.state.sortIDDelimiter + originalDupSortType + this.state.sortFieldDelimiter + sortFieldSplit[1];
                      }
                    }

                    return (
                      <Carousel.Item>
                        <div
                          key={index}
                          style={{
                            backgroundColor: 'black',
                            width: '350px',
                            height: '350' * (9 / 16) + 'px',
                            border: 'solid 2px black',
                            margin: '10px auto'
                          }}
                        >
                          <img
                            key={index}
                            style={{ height: '100%', width: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={'/api/images/campaign/' + imageSource}
                            alt='campaign_images'
                          />
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            );
          }}
          data={data}
          filterable
          filtered={this.state.filtered}
          onFilteredChange={(filtered, column, value) => {
            this.onFilteredChangeCustom(value, column.id || column.accessor);
          }}
          defaultFilterMethod={(filter, row) => {
            const id = filter.pivotId || filter.id;
            if (typeof filter.value === 'object') {
              return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
            } else {
              return row[id] !== undefined ? String(row[id].toLowerCase()).indexOf(filter.value) > -1 : true;
            }
          }}
          columns={[
            {
              columns: [
                {
                  expander: true,
                  Header: () => <strong>Display</strong>,
                  width: 65
                  // Expander: ({ isExpanded, ...rest }) => <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>,
                  // style: {
                  //   cursor: 'pointer',
                  //   fontSize: 25,
                  //   padding: '0',
                  //   textAlign: 'center',
                  //   userSelect: 'none'
                  // }
                },
                {
                  Header: 'Last Updated',
                  accessor: 'date',
                  width: 160
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Cell: row => (
                    <span>
                      <span style={{ color: row.value === 'On' ? '#57d500' : '#ff2e00' }}>&#x25cf;</span> {row.value}
                    </span>
                  ),
                  width: 60
                },
                {
                  Header: 'Source',
                  accessor: 'source',
                  width: 60,
                  Cell: row => (
                    <span>
                      <span style={{ color: row.value === 'PDM' ? '#57d500' : '#ff2e00' }}>&#x25cf;</span> {row.value}
                    </span>
                  )
                },
                {
                  Header: 'Business Name',
                  accessor: 'name',
                  width: 250
                },
                {
                  Header: 'Address',
                  accessor: 'address',
                  width: 200
                },
                {
                  Header: 'State',
                  accessor: 'state',
                  width: 200
                },
                {
                  Header: 'City',
                  accessor: 'city',
                  width: 200
                },
                {
                  Header: 'Zip',
                  accessor: 'zip',
                  width: 100
                },
                {
                  Header: 'Phone',
                  accessor: 'phone',
                  width: 100
                },
                {
                  Header: 'Category',
                  accessor: 'category',
                  width: 200
                },
                {
                  Header: 'Subcategory',
                  accessor: 'subcategory',
                  width: 200
                }
              ]
            }
          ]}
          defaultPageSize={10}
          className='-striped -highlight'
        />
      </div>
    );
  }
}

export default Development;
