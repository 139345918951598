import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { submitContact } from '../../redux/actions/mailActions';
import { Button } from 'react-bootstrap';

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      fields: { name: '', company: '', email: '', city: '', state: '', phone: '', message: '', checkbox: '' },
      errors: {}
    };
    this.onSumbitContact = this.onSumbitContact.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  onSumbitContact = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', this.state.fields['name']);
    formData.append('email', this.state.fields['email']);
    formData.append('city', this.state.fields['city']);
    formData.append('state', this.state.fields['state']);
    formData.append('phone', this.state.fields['phone']);
    formData.append('message', this.state.fields['message']);
    formData.append('checkbox', this.state.fields['checkbox']);
    this.props.submitContact(formData, this.props.history);
  };
  render() {
    return (
      <div className='component-body'>
        <div id='Form-replaceable'>
          <Form onSubmit={this.onSumbitContact}>
            <Form.Group>
              <Form.Control
                type='text'
                className='input1_custom_index'
                onChange={this.handleChange.bind(this, 'name')}
                placeholder='Name*'
                value={this.state.fields['name']}
              />
              <br />
              <Form.Control
                type='text'
                className='input1_custom_index'
                onChange={this.handleChange.bind(this, 'company')}
                placeholder='Company'
                value={this.state.fields['company']}
              />
              <br />
              <Form.Control
                type='email'
                className='input1_custom_index'
                onChange={this.handleChange.bind(this, 'email')}
                placeholder='Email*'
                value={this.state.fields['email']}
              />
              <br />
              <div className='flexbox-container'>
                <div className='flexbox-1'>
                  <Form.Control
                    type='text'
                    className='input1_custom_index'
                    onChange={this.handleChange.bind(this, 'city')}
                    placeholder='City*'
                    value={this.state.fields['city']}
                  />
                </div>
                <div className='flexbox-1'>
                  <Form.Control as='select' onChange={this.handleChange.bind(this, 'state')} className='input1_custom_index'>
                    <option value=''>State/Province*</option>
                    <option value='AL'>Alabama</option>
                    <option value='AK'>Alaska</option>
                    <option value='AZ'>Arizona</option>
                    <option value='AR'>Arkansas</option>
                    <option value='CA'>California</option>
                    <option value='CO'>Colorado</option>
                    <option value='CT'>Connecticut</option>
                    <option value='DE'>Delaware</option>
                    <option value='DC'>District Of Columbia</option>
                    <option value='FL'>Florida</option>
                    <option value='GA'>Georgia</option>
                    <option value='HI'>Hawaii</option>
                    <option value='ID'>Idaho</option>
                    <option value='IL'>Illinois</option>
                    <option value='IN'>Indiana</option>
                    <option value='IA'>Iowa</option>
                    <option value='KS'>Kansas</option>
                    <option value='KY'>Kentucky</option>
                    <option value='LA'>Louisiana</option>
                    <option value='ME'>Maine</option>
                    <option value='MD'>Maryland</option>
                    <option value='MA'>Massachusetts</option>
                    <option value='MI'>Michigan</option>
                    <option value='MN'>Minnesota</option>
                    <option value='MS'>Mississippi</option>
                    <option value='MO'>Missouri</option>
                    <option value='MT'>Montana</option>
                    <option value='NE'>Nebraska</option>
                    <option value='NV'>Nevada</option>
                    <option value='NH'>New Hampshire</option>
                    <option value='NJ'>New Jersey</option>
                    <option value='NM'>New Mexico</option>
                    <option value='NY'>New York</option>
                    <option value='NC'>North Carolina</option>
                    <option value='ND'>North Dakota</option>
                    <option value='OH'>Ohio</option>
                    <option value='OK'>Oklahoma</option>
                    <option value='OR'>Oregon</option>
                    <option value='PA'>Pennsylvania</option>
                    <option value='RI'>Rhode Island</option>
                    <option value='SC'>South Carolina</option>
                    <option value='SD'>South Dakota</option>
                    <option value='TN'>Tennessee</option>
                    <option value='TX'>Texas</option>
                    <option value='UT'>Utah</option>
                    <option value='VT'>Vermont</option>
                    <option value='VA'>Virginia</option>
                    <option value='WA'>Washington</option>
                    <option value='WV'>West Virginia</option>
                    <option value='WI'>Wisconsin</option>
                    <option value='WY'>Wyoming</option>
                  </Form.Control>
                </div>
              </div>
              <br />
              <Form.Control
                type='text'
                className='input1_custom_index'
                onChange={this.handleChange.bind(this, 'phone')}
                placeholder='Phone Number*'
                value={this.state.fields['phone']}
              />
              <br />
              {/* <div className='content' dangerouslySetInnerHTML={{ __html: messageInfo }} /> */}
              <Form.Control
                as='textarea'
                className='input1_custom_index'
                rows='8'
                cols='100'
                onChange={this.handleChange.bind(this, 'message')}
                placeholder='Message*'
                value={this.state.fields['message']}
              />{' '}
            </Form.Group>
            <Form.Group>
              <Form.Check
                type='checkbox'
                onChange={this.handleChange.bind(this, 'checkbox')}
                label='Keep me up to date with Pink Dot Media news and the latest information on products and services.'
              />
            </Form.Group>
            <div style={{ color: 'red', textAlign: 'left' }}>{this.state.errors.name}</div>
            <div style={{ color: 'red', textAlign: 'left' }}>{this.state.errors.email}</div>
            <div style={{ color: 'red', textAlign: 'left' }}>{this.state.errors.city}</div>
            <div style={{ color: 'red', textAlign: 'left' }}>{this.state.errors.state}</div>
            <div style={{ color: 'red', textAlign: 'left' }}>{this.state.errors.phone}</div>
            <div style={{ color: 'red', textAlign: 'left' }}>{this.state.errors.message}</div>
            <Button type='submit'>Submit</Button>
            <br />
            <br />* indicates required field
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});
export default connect(
  mapStateToProps,
  {
    submitContact
  }
)(withRouter(ContactForm));

// if (typeof fields['phone'] != 'undefined') {
//   const regexp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
//   if (!regexp.test(fields['phone'])) {
//     formIsValid = false;
//     errors['phone'] = '10-digit phone number is required.';
//   }
// }
