import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import '../assets/css/home.css';

import icon_digital from '../assets/images/digital.png';
import icon_network from '../assets/images/group.png';
import icon_target from '../assets/images/focus.png';
import icon_location from '../assets/images/map.png';
import icon_value from '../assets/images/value.png';
import icon_sale from '../assets/images/product.png';
import landing_video from '../assets/videos/landing_video.mp4';
import ContactForm from './components/form_contact';

class Home extends Component {
  constructor() {
    super();
    this.playLandingVideo = this.playLandingVideo.bind(this);
  }

  playLandingVideo() {
    // You can use the play method as normal on your video ref
    this.refs.vidRef.play();
  }

  componentDidMount() {
    this.playLandingVideo();
  }

  render() {
    return (
      <div className='home-body'>
        {/* <div className='body-group' style={{ backgroundColor: '#eeeeee' }}>
          <Container>
            <div className='group-content' style={{ fontSize: '1.5em' }}>
              <center>
                <a href='/join'>Join Our Network</a> to generate additional income for your business or{' '}
                <a href='/#advertisewithus'>Advertise With Us</a> to increase your brand awareness and bring in more customers.
                <br />
                <br />
                <Button type='button' href='/join' style={{ margin: '10px 20px' }}>
                  Join Us
                </Button>
                <Button type='button' href='/#advertisewithus' style={{ margin: '10px 20px' }}>
                  Advertise With Us
                </Button>
              </center>
            </div>
          </Container>
        </div> */}

        <div className='video-container'>
          <video ref='vidRef' src={landing_video} muted autoPlay loop type='video/mp4' id='video-landing' />
          <div className='video-message-container'>
            <Container>
              <div className='video-message'>Your audience is waiting...</div>

              <span className='video_message_sub'>
                <br />
                Showcase and promote your brand on the most targeted network for women.
                <br />
                <br />
              </span>
            </Container>
          </div>
        </div>
        <div className='body-group' style={{ backgroundColor: '#eeeeee' }}>
          <Container>
            <div className='group-content' style={{ fontFamily: 'ProximaNova' }}>
              Pink Dot Media caters to the growing importance of the female shopper, mother, career woman, trendsetter and recommender through a
              nationwide network of upscale nail salons and spas.
              <br />
              <br />
              There are over 130,000 nail salons in the US averaging 3,000 clients per month. Women who frequent salons do so every 1-2 weeks on
              average, spending 30-90 minutes per visit. 53% of them consider themselves purchase influencers.
            </div>
          </Container>
          <span id='advertisewithus' />
        </div>
        <div className='body-group'>
          <Container>
            <div>
              <center>
                <h1 className='h1_custom_index'>Why People Use Pink Dot Media</h1>
                <br />

                <br />
                <br />
                <h2 className='h2_custom_index'>Advertise on our platform to promote your business.</h2>
                <br />
                <Button type='button' href='/#contact' style={{ margin: '0 20px' }}>
                  Let's Get Started
                </Button>
              </center>
              <br />
              <br />
              <div className='flexbox-container'>
                <div className='flexbox-1'>
                  <img src={icon_network} alt='thumbnail' className='img-icon-home' />
                  <p>
                    <b>Massive Network</b>
                    <br />
                    <br />
                    Women are powerful influencers in today's society. Their social presence allow them to reach great audiences worldwide. Our
                    platform helps you tap into their network.
                  </p>
                </div>

                <div className='flexbox-1'>
                  <img src={icon_target} alt='thumbnail' className='img-icon-home' />
                  <p>
                    <b>Targeted</b>
                    <br />
                    <br />
                    Average customer spends over 60 minutes in a nail salon, and is seated without distraction. This level of attention is
                    unduplicated in any other Out of Home Network.
                  </p>
                </div>
              </div>

              <div className='flexbox-container'>
                <div className='flexbox-1'>
                  <img src={icon_value} alt='thumbnail' className='img-icon-home' />
                  <p>
                    <b>Invested Value and Return</b>
                    <br />
                    <br />
                    Average consumer visits the nail salon once every two weeks, creating a target for repeat messaging. Cost effective CPMS with
                    highly targeted impressions.
                  </p>
                </div>

                <div className='flexbox-1'>
                  <img src={icon_location} alt='thumbnail' className='img-icon-home' />
                  <p>
                    <b>Location and Coverage</b>
                    <br />
                    <br />
                    There are over 130,000 nail salons; you get to choose where your ads go. Deployable at both the local and national level.
                  </p>
                </div>
              </div>

              <div className='flexbox-container'>
                <div className='flexbox-1'>
                  <img src={icon_digital} alt='thumbnail' className='img-icon-home' />
                  <p>
                    <b>All Digital</b>
                    <br />
                    <br />
                    We deliver your contents through our digital medium that is fast and reliable. No more costly printing or other time consuming
                    process.
                  </p>
                </div>
                <div className='flexbox-1'>
                  <img src={icon_sale} alt='thumbnail' className='img-icon-home' />
                  <p>
                    <b>Sales and Branding</b>
                    <br />
                    <br />
                    60-minute average dwell time combined with inability to change channels. Your ad content appears once every 10 seconds.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className='body-group' style={{ backgroundColor: '#eeeeee' }}>
          <Container>
            <div className='group-content' style={{ fontFamily: 'ProximaNova' }}>
              It’s our goal to inform and get people excited about what's new and trending. Keep moving foward to build a platform that gives you the
              power to broadcast your messages to the next generation of women.
            </div>
          </Container>
          <span id='contact' />
        </div>
        <div className='body-group'>
          <Container>
            <div>
              <h2 className='h1_custom_index'>Contact Us</h2>
              <br />
              <span className='h3_custom_index'>Please send us an email with your question or comment and we will respond shortly.</span>
              <br />
              <br />
              <ContactForm />
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Home;
