import React, { Component } from "react";
import { Button, Form, Container } from "react-bootstrap";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import { verifyPassAssist } from "../../redux/actions/authActions";

class ForgotPasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email
    };

    this.props.verifyPassAssist(userData, this.props.history);
  }

  render() {
    const { errors } = this.state;
    return (
      <Container>
        <Form onSubmit={this.onSubmit}>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              className={classnames("input2_custom_index", {
                "is-invalid ": errors.email
              })}
              type="email"
              placeholder=""
              name="email"
              value={this.state.email}
              onChange={this.onChange}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" style={{ width: "100%" }} className="btn btn-primary">
            Reset
          </Button>
        </Form>
      </Container>
    );
  }
}

ForgotPasswordForm.propTypes = {
  verifyPassAssist: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { verifyPassAssist }
)(withRouter(ForgotPasswordForm));
