import React, { Component } from 'react';
import { Collapse, Button, Tabs, Tab } from 'react-bootstrap';
import '../assets/css/page.css';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

// import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createRole, updateRole, updateUser } from '../redux/actions/authActions';
import queryString from 'query-string';
import sanitizeHtml from 'sanitize-html-react';

const getColumnWidth = (rows, headerText, accessor) => {
  const maxWidth = 400;
  const magicSpacing = 10;
  const cellLength = Math.max(...rows.map(row => (`${row[accessor]}` || '').length), headerText.length);
  return Math.min(maxWidth, cellLength * magicSpacing);
};

class Users extends Component {
  constructor() {
    super();
    this.state = {
      filtered: [],
      userList: [],
      roleList: [],
      newRole: '',
      selectedUserRoleIDArray: [],
      collapseOpen: false,
      errors: {}
    };

    this.onCreateRole = this.onCreateRole.bind(this);
    this.onUpdateRole = this.onUpdateRole.bind(this);
    this.onUpdateUser = this.onUpdateUser.bind(this);

    this.onChangeUserRoleID = this.onChangeUserRoleID.bind(this);

    this.renderRoleEditable = this.renderRoleEditable.bind(this);
    this.renderUserEditable = this.renderUserEditable.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/login');
    }

    fetch('/api/users/list_users')
      .then(res => res.json())
      .then(userList => {
        this.setState({ userList });
      });
    fetch('/api/users/list_roles')
      .then(res => res.json())
      .then(roleList => {
        this.setState({ roleList });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  onCreateRole = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('newRole', this.state.newRole);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.createRole(formData, this.props.history);
  };

  onUpdateRole(roleID, role) {
    const formData = new FormData();
    formData.append('roleID', roleID);
    formData.append('role', role);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.updateRole(formData, this.props.history);
  }
  onChangeUserRoleID = (i, e) => {
    let ids = [...this.state.selectedUserRoleIDArray]; // copy the array to a new array
    ids[i] = e; // set value of the index to the new array
    this.setState({ selectedUserRoleIDArray: ids }); // set state of the new array to the old array
  };
  onUpdateUser(userID, firstName, lastName, userEmail, selectedRoleID) {
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('userEmail', userEmail);
    formData.append('roleID', selectedRoleID);
    formData.append('authUserRoleID', this.props.auth.user.role_id);
    this.props.updateUser(formData, this.props.history);
  }

  keyPress(event) {
    // prevent contentEditable div from producing a new line
    if (event.charCode === 13) {
      event.preventDefault();
    }
  }

  renderRoleEditable(cellInfo) {
    // sanitize Html so we don't get html inserted into our database when user copies and paste from one field to another
    let editableList = this.state.roleList;
    var sanitizeInnerHtml = sanitizeHtml(editableList[cellInfo.index][cellInfo.column.id]);
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onKeyPress={this.keyPress}
        onBlur={e => {
          var sanitizeOnBlur = sanitizeHtml(e.target.innerHTML);
          const data = [...editableList];
          data[cellInfo.index][cellInfo.column.id] = sanitizeOnBlur;
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeInnerHtml
        }}
      />
    );
  }
  renderUserEditable(cellInfo) {
    // sanitize Html so we don't get html inserted into our database when user copies and paste from one field to another
    let editableList = this.state.userList;
    var sanitizeInnerHtml = sanitizeHtml(editableList[cellInfo.index][cellInfo.column.id]);
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onKeyPress={this.keyPress}
        onBlur={e => {
          var sanitizeOnBlur = sanitizeHtml(e.target.innerHTML);
          const data = [...editableList];
          data[cellInfo.index][cellInfo.column.id] = sanitizeOnBlur;
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeInnerHtml
        }}
      />
    );
  }
  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({ filtered: filtered });
  };

  render() {
    const { errors, userList, roleList, selectedUserRoleIDArray, collapseOpen } = this.state;
    // when updating data, this set the tab which the page is updated on as the default tab
    let urlParameters = queryString.parse(this.props.location.search);
    let defaultTab = urlParameters.tab !== 'undefined' ? urlParameters.tab : 'users';

    return (
      <div className='page-body-container'>
        <div className='page-body'>
          <br />
          <h2>
            <a href='/dashboard' className='common-link' style={{ float: 'left' }}>
              {''}
              Back
            </a>
            <center>Team</center>
          </h2>
          <hr />
          <Tabs defaultActiveKey={defaultTab} id='uncontrolled-tab-example'>
            <Tab eventKey='users' title='Users'>
              <br />
              <center>
                <span style={{ color: '#f00' }}>{errors.errorUpdateUser}</span>
              </center>
              <ReactTable
                data={userList}
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
                columns={[
                  {
                    columns: [
                      {
                        Header: '#',
                        width: getColumnWidth(userList, '#', 'first_name'),
                        Cell: row => <center>{row.index + 1}</center>
                      },
                      {
                        Header: 'First Name',
                        accessor: 'first_name',
                        Cell: this.renderUserEditable,
                        width: getColumnWidth(userList, 'First Name', 'first_name')
                      },
                      {
                        Header: 'Last Name',
                        accessor: 'last_name',
                        Cell: this.renderUserEditable,
                        width: getColumnWidth(userList, 'Last Name', 'last_name')
                      },
                      {
                        Header: 'Email',
                        accessor: 'email',
                        Cell: this.renderUserEditable,
                        width: getColumnWidth(userList, 'Email', 'email')
                      },
                      {
                        Header: 'Role',
                        accessor: 'role',
                        Cell: row => (
                          <center>
                            <select
                              onChange={event => this.onChangeUserRoleID(row.index, event.target.value)}
                              value={selectedUserRoleIDArray[row.index] ? selectedUserRoleIDArray[row.index] : row.original.role_id}
                            >
                              {roleList.map((mapRole, i) => {
                                return (
                                  <option key={i} value={mapRole.id}>
                                    {mapRole.role}
                                  </option>
                                );
                              })}
                            </select>
                          </center>
                        )
                      },
                      {
                        Header: 'Action',
                        Cell: row => {
                          if (!selectedUserRoleIDArray[row.index]) {
                            selectedUserRoleIDArray[row.index] = row.original.role_id;
                          }
                          return (
                            <center>
                              <button
                                onClick={() =>
                                  this.onUpdateUser(
                                    row.original.user_id,
                                    row.original.first_name,
                                    row.original.last_name,
                                    row.original.email,
                                    selectedUserRoleIDArray[row.index]
                                  )
                                }
                              >
                                Update
                              </button>
                            </center>
                          );
                        }
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom
                className='-striped -highlight'
              />
            </Tab>
            <Tab eventKey='roles' title='Roles'>
              <Button style={{ margin: '20px auto' }} onClick={() => this.setState({ collapseOpen: !collapseOpen })}>
                Create a New Role Form
              </Button>
              <Collapse in={collapseOpen}>
                <div className='flexbox-container'>
                  <div className='flexbox-1' style={{ padding: '1px', margin: '1px' }}>
                    <fieldset style={{ width: '100%' }}>
                      <legend visible='true'>New Role</legend>
                      <form onSubmit={this.onCreateRole}>
                        <input
                          style={{ padding: '10px', margin: '5px 3px', width: '100%' }}
                          type='text'
                          value={this.state.newRole}
                          placeholder='Role Name'
                          onChange={e => {
                            const inputValue = e.target.value;
                            this.setState({ newRole: inputValue });
                          }}
                        />{' '}
                        <span style={{ color: '#f00' }}>{errors.errorCreateRole}</span>
                        <br />
                        <Button type='submit'>Create Role</Button>
                      </form>
                    </fieldset>
                  </div>
                </div>
              </Collapse>

              <center>
                <span style={{ color: '#f00' }}>{errors.errorUpdateRole}</span>
              </center>

              <ReactTable
                data={roleList}
                columns={[
                  {
                    columns: [
                      {
                        Header: '#',
                        minWidth: 30,
                        Cell: row => <center>{row.index + 1}</center>
                      },
                      {
                        Header: 'Role',
                        accessor: 'role',
                        Cell: this.renderRoleEditable
                      },
                      {
                        Header: 'Action',
                        Cell: row => (
                          <center>
                            <button onClick={() => this.onUpdateRole(row.original.id, row.original.role)}>Update</button>
                          </center>
                        )
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom
                className='-striped -highlight'
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === 'object') {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined
                      ? String(row[id])
                          .toLowerCase()
                          .indexOf(filter.value) > -1
                      : true;
                  }
                }}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  auth: propTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { createRole, updateRole, updateUser }
)(withRouter(Users));
