import React, { Component } from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames'; // use if else for classnames
import { connect } from 'react-redux'; // connecting redux to component
import { registerUser } from '../../redux/actions/authActions';
import ReCAPTCHA from 'react-google-recaptcha';
import TextFieldGroup from './text_field_group';

const RECAPTCHA_SITE_KEY = '6LcGg6UUAAAAAPpnP-waQmp_SJaROmBnNcJbLxtn';

class RegisterForm extends Component {
  constructor() {
    super();

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password2: '',
      accesskey: '',
      captcha: '',
      captcha_expired: false,
      errors: {}
    };
    this.reCaptchaRef = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
  }

  // redirect user if user is authenticated
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCaptchaChange(value) {
    if (value === null) {
      this.setState({ captcha_expired: 'true' });
      this.setState({ captcha: '' });
    } else {
      this.setState({ captcha: value });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const registerData = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      accesskey: this.state.accesskey,
      captcha: this.state.captcha
    };

    this.props.registerUser(registerData, this.props.history);
  }

  render() {
    const { errors } = this.state;
    return (
      // use "noValidate" on form for server side validation
      <Container>
        <Form noValidate onSubmit={this.onSubmit}>
          <TextFieldGroup
            type='text'
            name='first_name'
            placeholder='First Name'
            value={this.state.first_name}
            onChange={this.onChange}
            error={errors.first_name}
          />
          <TextFieldGroup
            type='text'
            name='last_name'
            placeholder='Last Name'
            value={this.state.last_name}
            onChange={this.onChange}
            error={errors.last_name}
          />
          <TextFieldGroup
            type='email'
            name='email'
            placeholder='Email Address'
            value={this.state.email}
            onChange={this.onChange}
            error={errors.email}
          />
          <TextFieldGroup
            type='password'
            name='password'
            placeholder='Password - must be at least 6 characters'
            value={this.state.password}
            onChange={this.onChange}
            error={errors.password}
          />
          <TextFieldGroup
            type='password'
            name='password2'
            placeholder='Re-enter Password'
            value={this.state.password2}
            onChange={this.onChange}
            error={errors.password2}
          />
          <TextFieldGroup
            type='password'
            name='accesskey'
            placeholder='Access Key'
            value={this.state.accesskey}
            onChange={this.onChange}
            error={errors.accesskey}
          />

          <Form.Group>
            <ReCAPTCHA
              className={classnames('input2_custom_index', {
                'is-invalid ': errors.captcha
              })}
              ref={this.reCaptchaRef}
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={this.onCaptchaChange}
            />
            <span style={{ color: '#f00', fontSize: '14px' }}>{errors.captcha}</span>
          </Form.Group>

          <Button type='submit'>Sign Up</Button>
        </Form>
        <br />
        Signing up signifies that you have read and agree to the <a href='/about/termsofuse'>Terms of Service</a> and our{' '}
        <a href='/about/privacypolicy'>Privacy Policy</a>.
      </Container>
    );
  }
}

RegisterForm.propTypes = {
  registerUser: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired
};

// use this to get reducer state into components, gets the state."auth" from combineRootReducer.js
// and put it into this auth. if "auth" from that file is changed to another word, then
// this state."auth" also needs to change. To get data, use this.props.auth.whatever
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(RegisterForm));
