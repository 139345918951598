import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import './assets/css/App.css';

// bring in pages and layout
import Error404 from './pages/page_error404';
import Header from './pages/layout/layout_header';
import Footer from './pages/layout/layout_footer';
import Home from './pages/page_home';
import About from './pages/page_about';
import TermsOfUse from './pages/page_termsofuse';
import PrivacyPolicy from './pages/page_privacypolicy';
import Register from './pages/page_register';
import Login from './pages/page_login';
import PassAssist from './pages/page_passassist';
import PassCode from './pages/page_passcode';
import PassReset from './pages/page_passreset';
import RegisterCode from './pages/page_registercode';

import JoinPage from './pages/page_join';

import DevelopmentPage from './pages/page_development';
import Dashboard from './pages/page_dashboard';
import Users from './pages/page_users';
import Campaign from './pages/page_campaigns';
import Displays from './pages/page_displays';
import Partners from './pages/page_partners';
import store from './redux/redux_store';
// need for token checks
import jwt_decode from 'jwt-decode';
import setAuthToken from './utilities/setAuthToken';
import { setCurrentUser, logoutUser } from './redux/actions/authActions';

// check for token for every page request
if (localStorage.loginToken) {
  // set auth token header auth
  setAuthToken(localStorage.loginToken);
  // decode token and get user data and expiration
  const decoded = jwt_decode(localStorage.loginToken);
  // set current user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // check for expired token
  const currentTime = Date.now() / 10000;
  if (decoded.exp < currentTime) {
    //logout user
    store.dispatch(logoutUser());
    // clear current profiles

    // redirect to login page
    window.location.href = '/login';
  }
}

class App extends Component {
  render() {
    return (
      <div id='App-body'>
        <CookieConsent buttonText='X'>
          <center>
            By using our site, you acknowledge that you have read and understand our <a href='/about/privacypolicy'>cookie policy</a>,{' '}
            <a href='/about/privacypolicy'>privacy policy</a>, and our <a href='/about/termsofuse'>terms of service</a>.
          </center>
        </CookieConsent>
        <Header history={this.props.history} />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/about/termsofuse' component={TermsOfUse} />
          <Route exact path='/about/privacypolicy' component={PrivacyPolicy} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/registercode/:id' component={RegisterCode} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/passassist' component={PassAssist} />
          <Route exact path='/passcode/:id' component={PassCode} />
          <Route exact path='/passreset/:id' component={PassReset} />
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/join' component={JoinPage} />
          <Route exact path='/company/campaigns' component={Campaign} />
          <Route exact path='/company/displays' component={Displays} />
          <Route exact path='/company/partners' component={Partners} />
          <Route exact path='/company/users' component={Users} />
          <Route exact path='/company/development' component={DevelopmentPage} />
          <Route component={Error404} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
